import Company from './company'
import PlanitModel from './planit-model'

export default class SsoConfiguration extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      company_name: this.attr(),
      idp_entity_id: this.attr(),
      idp_sso_url: this.attr(),
      idp_sls_url: this.attr(),
      idp_cert: this.attr(),
      idp_name: this.attr(),

      sp_entity_id: this.attr(),
      sp_acs_url: this.attr(),
      sp_sls_url: this.attr(),

      metadata_url: this.attr(),

      disable_registration: this.attr(),
      enabled_password_login: this.attr(),

      sso_config_type: this.attr(),

      server_saml_settings: this.attr(),
      server_saml_advanced_settings: this.attr(),

      accepted_domains: this.attr(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),


      technical_contact_name: this.attr(),
      technical_contact_email: this.attr(),
      support_contact_name: this.attr(),
      support_contact_email: this.attr(),
      organization_name: this.attr(),
      organization_display_name: this.attr(),
      organization_url: this.attr(),
      server_sp_x509cert: this.attr(),
    }
  }

  static async getServerSsoConfig() {
    return await SsoConfiguration.api().get('/sso-configurations/sso-server-configurations').catch((error) => {
      console.log("no server sso config found")
    })
  }

  async patch(data) {
    return await SsoConfiguration.api().patch(this.url(), data)
  }

  async patchServerConfig(data) {
    return await SsoConfiguration.api().patch('/sso-configurations/sso-server-configurations', data)
  }

  static async createServerSsoConfig(data) {
    return await SsoConfiguration.api().post('/sso-configurations', data)
  }
  async saml_metadata_import(formData) {

    return await SsoConfiguration.api().request({
      method: 'patch',
      url: this.url() + '/saml_metadata_import',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

SsoConfiguration.entity = 'sso-configurations'
