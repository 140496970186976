

import store from '@state/store'
import TranslationContingent from '@state/models/translation-contingent'

export const state = {
    max_characters: null,
    active: null,
    deepl_setting_active: null,
}

export const mutations = {
  SET_ACTIVE_STATE(state, value) {
    state.active = value
  },
  SET_MAX_CHARACTERS(state, value) {
    state.max_characters = value
  },
  SET_DEEPL_SETTING_ACTIVE(state, value) {
    state.deepl_setting_active = value
  }
}

export const getters = {
  translationContingent (state) {
    return TranslationContingent
			.query()
			.first()
		},
    active (state) {
        return state.active
    },
    free_contingent (state) {
        return state.free_contingent
    },
    deepl_setting_active (state) {
        return state.deepl_setting_active
    }
}

export const actions = {
  async initTranslationContingent({ commit, getters }, { document_id}) {
    const company = store.getters['navbar/selectedCompany']
		const params = {}
		if (document_id) {
			params.document_id = document_id
		}
    const result = await company.loadActiveTranslationContingent(params)
		const contingent = await getters.translationContingent

		if (contingent && contingent.is_active) {
      commit('SET_ACTIVE_STATE', contingent.active)
      commit('SET_MAX_CHARACTERS', contingent.max_characters)
      commit('SET_DEEPL_SETTING_ACTIVE', contingent.deepl_setting_active)
    }
  }
}
