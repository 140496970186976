import axios from 'axios'

import store from '../store'
import Address from './address'
import Basket from './basket'
import ChecklistUserActivation from './checklist-user-activation'
import CompanyRole from './company-role'
import Event from './event'
import Invite from './invite'
import Order from './order'
import PermissionRole from './permission-role'
import PermissionRoleUserAssociation from './permission-role-user-association'
import PlanitModel from './planit-model'
import TosAcceptance from './tos-acceptance'

export default class User extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      email: this.attr(),
      verified: this.boolean(),
      is_admin: this.boolean(),
      is_admin_explicitly_enabled: this.boolean(),
      is_manager: this.boolean(),
      can_create_license: this.boolean(),

      title: this.attr(),
      first_name: this.attr(),
      last_name: this.attr(),

      name: this.attr(),

      date_of_birth: this.attr(),

      locale: this.attr(),
      timezone: this.attr(),

      address_id: this.attr(null),
      address: this.hasOne(Address, 'user_id', 'address_id'),

      company_roles: this.hasMany(CompanyRole),

      image: this.attr(),

      force_logout: this.attr(),
      otp_enabled: this.attr(),

      admin_visible: this.attr(),

      app_version: this.attr(),

      permission_role_user_associations: this.hasMany(PermissionRoleUserAssociation, 'user_id'),

      admin_requested_by_user_id: this.attr(),
      admin_requested_by_user: this.belongsTo(User, 'admin_requested_by_user_id'),

      enable_autosave: this.boolean(),

      show_changelog_notification: this.boolean(),

      last_seen_changelog_id: this.attr(),

      show_support_btn: this.boolean(),

      active_checklist_id: this.attr(),
      checklist_user_activations: this.hasMany(ChecklistUserActivation, 'user_id'),

      is_demo_user: this.boolean(),
      has_dismissed_welcome_screen: this.boolean(),

      tos_acceptances: this.hasMany(TosAcceptance, 'user_id'),
      orders: this.hasMany(Order, 'user_id'),
      events: this.hasMany(Event, 'user_id'),

      has_unpaid_basket: this.attr(),
      archived: this.attr(),
      referred_by_permission_role : this.belongsTo(PermissionRole, 'user_id'),
      reseller_permission_role_id : this.attr(),

      // local
      password: this.attr(),
    }
  }

  refreshCompanyRoles() {
    return CompanyRole.api().get(this.url() + CompanyRole.$url())
  }

  refreshInvites() {
    return Invite.api()
      .get(this.url() + Invite.$url(), { save: false })
      .then((result) => {
        result.response.data.forEach((i) => {
          i.user_id = this.id
        })
        result.save()
        return result
      })
  }

  async delete() {
    const thisId = this.id
    await super.delete()
    CompanyRole.delete((cr) => cr.user_id === thisId)
    Invite.delete((i) => i.user_id === thisId)
  }

  enableTwoFactorAuth() {
    return axios.post('/api/users/' + this.id + '/enable-two-factor-auth')
  }

  async refreshBaskets() {
    const result = await this.refreshSubResource(Basket, { save: false })
    result.response.data.forEach((b) => (b.user_id = this.id))
    await result.save()
    return result
  }

  createDemoCompany(companyData, promotionId) {
    if (promotionId) {
      companyData.promotionId = promotionId
    }

    return this.constructor.api().post(this.url() + '/create-demo-company', companyData)
  }

  static async $count() {
    let count = 0
    await axios.get('/api/users', { params: { count: true } }).then((r) => {
      count = r.data.count
    })
    return count
  }

  $impersonate() {
    axios.post('/api' + this.url() + '/impersonate').then(async (r) => {
      await store.dispatch('auth/impersonate', {
        token: r.data.access_token,
        userId: r.data.user_id,
      })
      window.location.href = '/profile'
    })
  }
}

User.entity = 'users'
