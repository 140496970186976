import PlanitModel from './planit-model'
import User from './user'


export default class Event extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      event_type: this.attr(),
      data: this.attr(),

      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),
    }
  }
}

Event.entity = 'events'
