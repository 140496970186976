import '@fortawesome/fontawesome-pro/css/all.css';

import '@src/sass/styles.scss';

import { createVuetify } from 'vuetify';
import de from 'vuetify/lib/locale/de';
import cs from 'vuetify/lib/locale/cs';
import pl from 'vuetify/lib/locale/pl';
import es from 'vuetify/lib/locale/es';
import it from 'vuetify/lib/locale/it';
import fr from 'vuetify/lib/locale/fr';
import pt from 'vuetify/lib/locale/pt';
import ja from 'vuetify/lib/locale/ja';
import tr from 'vuetify/lib/locale/tr';
import nl from 'vuetify/lib/locale/nl';
import sv from 'vuetify/lib/locale/sv';
import hu from 'vuetify/lib/locale/hu';
import el from 'vuetify/lib/locale/el';
import { aliases, fa } from 'vuetify/iconsets/fa';
import { mdi } from 'vuetify/iconsets/mdi';
import { VDateInput } from 'vuetify/labs/VDateInput';
import { VStepperVertical, VStepperVerticalItem } from 'vuetify/labs/VStepperVertical';
import { VTimePicker } from 'vuetify/labs/VTimePicker';
import { VTreeview } from 'vuetify/labs/VTreeview';

import defaultThemes from './default-themes';


const vuetify = createVuetify({
  components: {
    VTreeview,
    VStepperVertical,
    VStepperVerticalItem,
    VTimePicker,
    VDateInput,
  },
  display: {
    thresholds: {
      xs: 768,
      sm: 960,
      md: 1424,
      lg: 1904,
    },
    mobileBreakpoint: 'xs',
  },
  theme: {
    options: {
      customProperties: true,
    },
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: defaultThemes.light.colors,
      },
      dark: {
        dark: true,
        colors: defaultThemes.dark.colors,
      },
    },
    variations: {
      colors: ['primary', 'error', 'success', 'warning', 'info', 'primaryDesaturated'],
      lighten: 5,
      darken: 5,
    },
  },
  icons: {
    defaultSet: 'fa',
    aliases: {
      ...aliases,
      angleDown: 'fas fa-angle-down',
      angleRight: 'fas fa-angle-right',
    },
    sets: {
      mdi,
      fa,
    },
  },
  locale: {
    locale: window.lang || 'en',
    fallback: 'en',
    messages: { de, es, pl, cs ,ja ,fr ,pt ,it, sv, hu ,tr ,el ,nl,},
  },
  /* date: {
    adapter: DateFnsAdapter,
    locale: {
      de: deDateLocale,
    },
  }, */
})

export default vuetify
