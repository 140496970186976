import PlanitModel from './planit-model'
import Timer from '@state/models/timer'
import Address from '@state/models/address'

export default class TranslationContingent extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      max_characters: this.attr(),
      used_characters: this.attr(),

      is_active: this.attr(),

      company_id: this.attr(),
      company: this.belongsTo(Address, 'company_id'),

      timer_id: this.attr(),
      timer: this.belongsTo(Timer, 'timer_id'),

      deepl_setting_active: this.attr(),
      estimated_remaining_translations: this.attr(),
      limit_reached_for_document: this.attr(),
    }
  }
  async refreshContingent() {
    return await TranslationContingent.api().get(this.url())
  }
}

TranslationContingent.entity = 'translation-contingent'
