import hexToHsl from '@utils/hex-to-hsl'

import i18n from '@src/plugins/vue-i18n'
import {
  sortBy,
} from 'lodash'


import {
  LicenseType,
  PermissionRoleType,
  SupportedLocale,
  MainLocale
} from '@enums'

import store from '@state/store'

import BrandSetting from '@state/models/brand-setting'
import PermissionRole from '@state/models/permission-role'
import PermissionRoleCompanyAssociation from '@state/models/permission-role-company-association'


export const state = {
  //themeCSrgb: false,
  themeCSrgb: 0.4, // dark
  appbarCSrgb: 0.7, // navbar is light by default
  currentFolderRefreshPromise: null,
  showUnauthorizedError: false,
  unauthorizedPermissionRole: false,
  initDone: false,
  dragging: false, // global dragging state to disable editor drop

  die3Mode: false, // special marketing dings

  currCompanyGptPrca: null,

  cachedUserLastSeenChangelogId: null,

  showCheckoutModal: false,
  partnerCode: null,

  routerLoading: false,

  layoutFullWidth: null,

  showInfoCenter: false,
  infoCenterSearchString: null,

  hasCustomTheme: false,

  debugInstanceUpToDateOverride: true,

  vuetifyTheme: null,
}

export const mutations = {
  SET_VUETIFY_THEME(state, theme) {
    state.vuetifyTheme = theme
  },
  SET_THEME_COLORS(state, { lightColors, darkColors }) {
    state.vuetifyTheme.themes.light.colors = lightColors
    state.vuetifyTheme.themes.dark.colors = darkColors
  },
  SET_THEME_CSRGB(state, { themeCSrgb }) {
    state.themeCSrgb = themeCSrgb
  },
  SET_APPBAR_CSRGB(state, { appbarCSrgb }) {
    state.appbarCSrgb = appbarCSrgb
  },
  SET_SHOW_UNAUTHORIZED_ERROR(state, showUnauthorizedError) {
    state.showUnauthorizedError = showUnauthorizedError
  },
  SET_UNAUTHORIZED_PERMISSION_ROLE(state, unauthorizedPermissionRole) {
    state.unauthorizedPermissionRole = unauthorizedPermissionRole
  },
  SET_INIT_DONE(state, initDone) {
    state.initDone = initDone
  },
  SET_DRAGGING(state, value) {
    state.dragging = value
  },
  SET_DIE3MODE(state, value) {
    state.die3Mode = value
  },
  SET_SELECTED_COMPANY_GPT_PRCA(state, value) {
    state.currCompanyGptPrca = value
  },
  SET_CACHED_USER_LAST_SEEN_CHANGELOG_ID(state, value) {
    state.cachedUserLastSeenChangelogId = value
  },
  SET_SHOW_CHECKOUT_MODAL(state, value) {
    state.showCheckoutModal = value
  },
  SET_PARTNER_CODE(state, value) {
    state.partnerCode = value
  },
  SET_ROUTER_LOADING(state, value) {
    state.routerLoading = value
  },
  SET_LAYOUT_FULL_WIDTH(state, value) {
    state.layoutFullWidth = value
  },
  SET_INFO_CENTER_SEARCH_STRING(state, searchString) {
    state.infoCenterSearchString = searchString
  },
  SET_SHOW_INFO_CENTER(state, value) {
    state.showInfoCenter = value
  },
  SET_HAS_CUSTOM_THEME(state, value) {
    state.hasCustomTheme = value
  },
  SET_DEBUG_INSTANCE_UP_TO_DATE_OVERRIDE(state, value) {
    state.debugInstanceUpToDateOverride = value
  },
}

export const getters = {
  vuetifyTheme(state) {
    return state.vuetifyTheme
  },
  $t: () => (key, ...params) => {
    return i18n.global.t(key, ...params)
  },
  themeCSrgb(state) {
    return state.themeCSrgb
  },
  appbarCSrgb(state) {
    return state.appbarCSrgb
  },
  localeSelectItems(state, getters) {
    const brandSetting = BrandSetting.query().first()
    const $t = getters.$t

    if (
      !brandSetting ||
      !brandSetting.supported_locales ||
      !brandSetting.supported_locales.length
    ) {
      return Object.values(SupportedLocale).map((localeValue) => {
        return {
          text: $t('enums.supported_locale.' + localeValue),
          value: localeValue,
        }
      })
    } else {
      return brandSetting.getLocaleSelectItems()
    }
  },
  localeSelectShortNameItems(state, getters) {
    return getters.localeSelectItems.map((i) => {
      return {
        ...i,
        text: i.value,
      }
    })
  },
  contentLocaleSelectShortNameItems(state, getters) {
    // Erstellen einer Liste mit vorhandenen Sprachen
    const contentLocaleSelectItems = []
    const selectedCompany = store.getters['navbar/selectedCompany']

    if (selectedCompany?.content_locales) {
      const contentLanguages = store.getters['navbar/contentLanguages']

      selectedCompany.content_locales.forEach((contentLocale) => {
        let text
        // Bestimmen des Textes basierend auf der Länge von contentLocale
        if (contentLocale.length === 2) {
          text = contentLanguages.find((l) => l.alpha_2 === contentLocale).name_translations[contentLocale]
        }

        // Prüfen, ob der Wert bereits existiert, bevor er hinzugefügt wird
        const exists = contentLocaleSelectItems.some((item) => item.value === contentLocale)

        if (!exists) {
          contentLocaleSelectItems.push({
            text,
            value: contentLocale,
          })
        }
      })
    }
    else {
      contentLocaleSelectItems.push({
        text: getters.$t('enums.supported_locale.en'),
        value: 'en',
      })
      contentLocaleSelectItems.push({
        text: getters.$t('enums.supported_locale.de'),
        value: 'de',
      })
    }
    // Sortierung basierend auf der Reihenfolge in selectedCompany.content_locales
    return sortBy(
      contentLocaleSelectItems,
      (i) => selectedCompany?.content_locales.indexOf(i.value)
    )
  },

  brandSetting() {
    return BrandSetting.query().first()
  },
  showUnauthorizedError(state) {
    return state.showUnauthorizedError
  },
  unauthorizedPermissionRole(state) {
    return state.unauthorizedPermissionRole
  },
  initDone(state) {
    return state.initDone
  },
  dragging(state) {
    return state.dragging
  },
  navbarDark(_, getters) {
    // was 0.55, but added 0.0038 to accomodate specific brand
    return getters.appbarCSrgb < 0.5538
  },
  die3Mode(state, getters) {
    return state.die3Mode
  },
  tosTo(state, getters) {
    if (getters.brandSetting && getters.brandSetting.tos_text) {
      return {
        name: 'brand.terms_of_use',
      }
    } else {
      return {
        path: '/' + getters.$t('components.footer.terms_of_use_path_name'),
      }
    }
  },
  deeplEnabled() {
    return window.deepl_integration && store.getters['auth/isAdmin']
  },
  canCompanyUseGptFeature(state) {
    return state.currCompanyGptPrca !== null
  },
  getGptPrcaForCompanyId(state) {
    return state.currCompanyGptPrca
  },
  cachedUserLastSeenChangelogId(state) {
    return state.cachedUserLastSeenChangelogId
  },
  showCheckoutModal(state) {
    return state.showCheckoutModal
  },
  partnerCode(state) {
    return state.partnerCode
  },
  showPartnerKBrand(state) {
    return [
      'eyJpZCI6MTgyMX0.YNsjX_OTAeR59T_ucrP3f_evtXQ',
      'eyJpZCI6MTgyMX0.YZlhz4gXW9a4EXCwxiQlmVCrXLM',
    ].indexOf(state.partnerCode) !== -1
  },
  routerLoading(state) {
    return state.routerLoading
  },
  primaInstanceUpToDate() {
    return (
      state.debugInstanceUpToDateOverride
      || window.primary_instance
      || window.instance_mode == 'demo'
      || window.current_prima_version === window.prima_version
    )
  },
  layoutFullWidth: state => state.layoutFullWidth,
  blueprintImportEnabled(_, getters) {
    return (
      !!getters.brandSetting &&
      getters.primaInstanceUpToDate &&
      store.getters['auth/isAdmin']
    )
  },
  productName(_, getters) {
    return getters.brandSetting?.name || 'PRIMA'
  },
  brandName(_, getters) {
    return getters.brandSetting?.name_brand || 'PLANIT //'
  },
  supportEmail(_, getters) {
    return getters.brandSetting?.support_email || 'mail@planitprima.com'
  },
  infoCenterSearchString: state => state.infoCenterSearchString,
  showInfoCenter: state => state.showInfoCenter,
  activeLicenseType() {
    return store.getters['auth/activeLicenseType']
  },
  // remove when nobody complains in the next few weeks
  /* fistBasicLicenseBeforeJan2024AndNotFreeNow(_, getters) {
    const isFirstBasicLicenseBeforeJan2024 = store.getters['auth/activeLicensePermissionRole']?.licenses.some((license) => {
      return (license.license_type === LicenseType.BASIC || license.license_type === LicenseType.PRO) &&
              new Date(license.license_start) < new Date(PRO_FEATURE_DATE_FRONTEND)
    })
    return (
      getters.activeLicenseType != LicenseType.FREE &&
      isFirstBasicLicenseBeforeJan2024
    )
  }, */
  hasCustomTheme: state => state.hasCustomTheme,
  backgroundLightColorDarken(state, getters) {
    const { h, s, l } = hexToHsl(getters.vuetifyTheme.themes.light.colors.background)
    return 'hsla(' + h + ',100%,22%, 0.90)'
  },
  backgroundLightColor(state, getters) {
    const { h, s, l } = hexToHsl(getters.vuetifyTheme.themes.light.colors.background)
    return 'hsla(' + h + ',100%,22%, 0.07)'
  },
  backgroundLighterColor(state, getters) {
    const theme = getters.vuetifyTheme.themes.light.colors.background
    const { h, s, l } = hexToHsl(theme)
    return `hsla(${h}, 100%, 22%, 0.04)`
  },
  backgroundLighterStyles(_, getters) {
    return {
      background: getters.backgroundLighterColor,
    }
  },
  backgroundLightStyles(_, getters) {
    return {
      background: getters.backgroundLightColor,
    }
  },
  primaryLightColor(state, getters) {
    const theme = getters.vuetifyTheme.themes.light.colors.background
    const { h, s, l } = hexToHsl(theme)
    return 'hsla(' + h + ',100%,27%, 0.05)'
  },
  isLicenseRestricted(state, getters) {
    return ({ minimumLicenseType }) => {
      if (!minimumLicenseType) {
        return false
      }

      const licenseValues = {
        [LicenseType.FREE]: 1,
        [LicenseType.BASIC]: 2,
        [LicenseType.PRO]: 3,
        [LicenseType.PROFESSIONAL]: 4,
        [LicenseType.RESELLER]: 5
      };

      return licenseValues[getters.activeLicenseType] < licenseValues[minimumLicenseType]

    }
  },
  fullWidth(state, getters) {
    if (getters.layoutFullWidth !== null) {
      return getters.layoutFullWidth
    }

    const $route = store.getters['navbar/currentRoute']

    return $route.matched.some(
      (matchedRoute) => matchedRoute.meta?.enableFullWidth
    ) || false

  },
  containerStyles(state, getters) {
    const styles = {}
      if (!getters.fullWidth) {
        styles['width'] = '100%'
        styles['max-width'] = '100rem'

      } else {
        styles['width'] = '100%'
        styles['max-width'] = '100%'
      }

      return styles
  },
  debugInstanceUpToDateOverride: state => state.debugInstanceUpToDateOverride,
}

export const actions = {
  init({ state, dispatch, getters }) {
  },

  setThemeCSrgb({ commit }, { themeCSrgb } = {}) {
    commit('SET_THEME_CSRGB', { themeCSrgb })
  },

  setAppbarCSrgb({ commit }, { appbarCSrgb } = {}) {
    commit('SET_APPBAR_CSRGB', { appbarCSrgb })
  },

  setShowUnauthorizedError({ commit }, showUnauthorizedError) {
    commit('SET_SHOW_UNAUTHORIZED_ERROR', showUnauthorizedError)
  },

  setUnauthorizedPermissionRole({ commit }, unauthorizedPermissionRole) {
    commit('SET_UNAUTHORIZED_PERMISSION_ROLE', unauthorizedPermissionRole)
  },
  async setGptPrcaForCompanyId({ commit, state, getters, rootState, rootGetters }) {
    await PermissionRole.$all({ permission_role_type: PermissionRoleType.GPT_EXTRACT_FEATURE })

    const company = rootGetters['navbar/selectedCompany']
    const pr = PermissionRole.allFast().find(
      (pr) => pr.permission_role_type === PermissionRoleType.GPT_EXTRACT_FEATURE
    )

    if (!company || !pr) {
      return
    }

    const prca = PermissionRoleCompanyAssociation
    .query()
    .where((prca) => prca.permission_role_id === pr.id && prca.company_id === company.id)
    .with('company')
    .first()

    commit('SET_SELECTED_COMPANY_GPT_PRCA', prca)

  },
  setInfoCenterSearchString({ commit, dispatch }, searchString) {
    commit('SET_INFO_CENTER_SEARCH_STRING', searchString)
    dispatch('setShowInfoCenter', true)

  },
  setShowInfoCenter({ commit }, value) {
    store.dispatch('navbar/closeAllRightDrawers')

    if (!value) {
      commit('SET_INFO_CENTER_SEARCH_STRING', null)
    }

    commit('SET_SHOW_INFO_CENTER', value)

  },
  setHasCustomTheme({ commit }, value) {
    commit('SET_HAS_CUSTOM_THEME', value)
  },
}
