<script>
import store from '@state/store'
import cursorManager from '@utils/cursor-manager'
import { de, en } from 'suneditor/src/lang'

export default {
  name: 'VueSunEditor',
  props: {
    value: { type: String, required: true },
    lang: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    focusOnCreation: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    extendedButtons: { type: Boolean, default: false },
    toolbarBottom: { type: Boolean, default: false },
    stickyToolbar: { type: Number, default: 0 }
  },
  emits: ['update:value', 'blur', 'focus'],
  data () {
    return {
      editor: null,
      internalValue: this.value,
      emptyValueWatchTriggered: false,
      singlePRegex: /^<p>((?:(?!<\/?p>).)*)<\/p>$/s
    }
  },
  watch: {
    disabled (newVal) {
      if (this.editor) {
        if (newVal) {
          this.editor.readOnly = true
          this.editor.disable()
        } else {
          this.editor.readOnly = false
          this.editor.enable()
        }
      }
    },
    value (newVal) {
      if (!newVal || newVal.length === 0) {
        this.emptyValueWatchTriggered = true
      }
      if (newVal !== this.internalValue) {
        this.internalValue = newVal
        if (this.editor) {
          this.editor.setContents(newVal || '')
          this.checkTabEnable()
        }
      }
    },
    placeholder (newVal) {
      if (this.editor) {
        this.reinitializeEditor();
      }
    }
  },
  mounted () {
    this.editor = this.initializeEditor()

    if (this.focusOnCreation) {
      this.$nextTick(() => {
        const editableEl = this.$refs.editor.parentElement.querySelector('div[contenteditable="true"]')
        if (editableEl) {
          editableEl.focus()
          cursorManager.setEndOfContenteditable(editableEl)
        }
      })
    }

    this.$nextTick(() => {
      this.addRemoveFormattingButton()
    })

    this.checkTabEnable()
    this.setEditorCallbacks()

    if (!this.disabled) {
      this.$nextTick(() => {
        this.addToggleToolbarButton()
      })
    }
  },
  unmounted () {
    if (this.editor) {
      this.editor.destroy()
    }
  },
  methods: {
    initializeEditor () {
      const locale = this.lang || store.getters['auth/currentLocale']
      const langObj = locale === 'de' ? de : en
      const config = {
        lang: langObj,
        stickyToolbar: this.stickyToolbar,
        mode: 'classic',
        callBackSave: contents => {
          console.log('Speicherbefehl ausgelöst. Inhalt:', contents)
        },
        placeholder: this.placeholder || ''
      }

      if (this.extendedButtons) {
        config.buttonList = [
          ['formatBlock'],
          ['bold', 'italic', 'underline'],
          ['align', 'list', 'link', 'fontColor', 'fontSize']
        ]
      }

      const sunInitEditor = store.getters['sunInitEditor/sunInitEditor']
      if (!sunInitEditor) {
        console.error('sunInitEditor ist nicht initialisiert.')
        return null
      }

      const editorInstance = sunInitEditor.create(this.$refs.editor, config)
      if (this.value) {
        editorInstance.setContents(this.value)
      }
      if (this.disabled) {
        editorInstance.readOnly = true
        editorInstance.disable()
      }
      return editorInstance
    },
    reinitializeEditor() {
      const currentContent = this.editor ? this.editor.getContents() : '';
      const hasFocus = document.activeElement &&
                      this.$refs.editor &&
                      this.$refs.editor.parentElement &&
                      this.$refs.editor.parentElement.contains(document.activeElement);

      if (this.editor) {
        this.editor.destroy();
      }

      this.editor = this.initializeEditor();

      if (currentContent) {
        this.editor.setContents(currentContent);
      }

      this.setEditorCallbacks();

      if (hasFocus) {
        this.$nextTick(() => {
          const editableEl = this.$refs.editor.parentElement.querySelector('div[contenteditable="true"]');
          if (editableEl) {
            editableEl.focus();
            cursorManager.setEndOfContenteditable(editableEl);
          }
        });
      }

      this.$nextTick(() => {
        this.addRemoveFormattingButton();
        this.addToggleToolbarButton();
      });
    },
    checkTabEnable () {
      if (
        this.internalValue &&
        this.editor &&
        this.editor.core.options.tabDisable &&
        (this.internalValue.includes('\n') || this.internalValue.includes('<br>'))
      ) {
        this.editor.setOptions({ tabDisable: false })
      }
    },
    setEditorCallbacks () {
      this.editor.showInline = (toolbar, context, core) => {
        if (this.toolbarBottom) {
          toolbar.style.top = '36px'
          if (core && core._inlineToolbarAttr) {
            core._inlineToolbarAttr.top = '36px'
          }
        }
      }

      this.editor.onChange = contents => {
        if (this.emptyValueWatchTriggered) {
          this.emptyValueWatchTriggered = false
          return
        }
        contents = contents.replace(this.singlePRegex, '$1')
        if (contents !== this.internalValue) {
          this.internalValue = contents
          this.$emit('update:value', contents)
        }
      }

      this.editor.onBlur = () => {
        this.$emit('blur')
      }
      this.editor.onFocus = () => {
        this.$emit('focus')
      }

      this.editor.onDrop = (e, cleanData, maxCharCount, core) => {
        if (store.getters['global/dragging']) {
          return false
        }
        return true
      }
    },
    addRemoveFormattingButton () {
      const btnTray = this.$refs.editor.parentElement.querySelector('.se-btn-tray')
      if (btnTray) {
        const removeBtn = document.createElement('button')
        removeBtn.type = 'button'
        removeBtn.className = 'se-btn se-btn-icon'
        removeBtn.innerHTML = '<i class="fas fa-eraser"></i>'
        removeBtn.title = this.$t('generic.remove_all_formattings')
        removeBtn.addEventListener('click', () => {
          const content = this.editor.getContents()
          const cleanContent = content.replace(/<[^>]*>/g, '')
          this.editor.setContents(cleanContent)
        })
        btnTray.appendChild(removeBtn)
      } else {
        console.error('Button-Tray nicht gefunden, custom Button konnte nicht hinzugefügt werden.')
      }
    },
    addToggleToolbarButton () {
      const parentEl = this.$refs.editor.parentElement
      if (!parentEl) return

      const wrapper = parentEl.querySelector('.se-wrapper')
      if (!wrapper) return

      const existingButton = wrapper.querySelector('.toggle-toolbar-button')
      if (existingButton) {
        existingButton.remove()
      }

      const button = document.createElement('button')
      button.style.position = 'absolute'
      button.style.right = '0'
      button.style.bottom = '0'
      button.style.width = '40px'
      button.style.height = '37px'
      button.style.color = 'rgba(0, 0, 0, 0.87)'
      button.style.borderRadius = 'var(--prima-border-radius-small)'
      button.style.zIndex = '10'
      button.setAttribute('tabindex', '-1')

      const iconEl = document.createElement('i')
      iconEl.classList.add('far', 'fa-fw', 'pa-w', 'fa-text-size')
      button.appendChild(iconEl)
      button.classList.add('toggle-toolbar-button')

      button.addEventListener('click', () => {
        const editorEl = parentEl.querySelector('.sun-editor')
        if (editorEl) {
          editorEl.classList.toggle('show-toolbar')
          button.classList.toggle('active')
        }
      })

      wrapper.appendChild(button)
    }
  }
}
</script>

<template>
  <div ref="editor"></div>
</template>

<style lang="scss">
@import '~suneditor/dist/css/suneditor.min.css';

.toggle-toolbar-button {
  padding: 4px;
  z-index: 10 !important;

  &.active {
    color: rgb(var(--v-theme-primary));
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.sun-editor {
  font-family: var(--prima-default-font) !important;
  padding: 0;
  border-radius: var(--prima-border-radius-small);
  border: none;
  color: rgba(0, 0, 0, 0.87);
  background-color: unset !important;

  &.show-toolbar .sun-editor-editable.suneditor-planit {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-width: 0 1px 1px 1px;
  }

  &.show-toolbar .se-toolbar {
    display: block !important;
  }
}

.sun-editor-editable.suneditor-planit {
  font-family: var(--prima-default-font) !important;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-radius: var(--prima-border-radius-small);
}

.sun-editor .se-toolbar-inline {
  box-shadow: none !important;
}

.sun-editor.show-toolbar .se-toolbar.se-toolbar-shadow {
  display: none !important;
}

.sun-editor .se-placeholder {
  padding: 8px !important;
  text-overflow: unset !important;
  white-space: unset !important;
  color: rgba(71, 71, 71, 0.6) !important;
}

.sun-editor .se-toolbar {
  background-color: transparent !important;
  outline: none !important;
  display: none !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-width: 1px 1px 0 1px !important;
  border-radius: var(--prima-border-radius-small) var(--prima-border-radius-small) 0 0;
}

.sun-editor .se-btn-tray {
  border: none !important;
  background-color: white !important;
  border-radius: var(--prima-border-radius-small) var(--prima-border-radius-small) 0 0;
  outline: none !important;
}

.sun-editor .se-btn-module-border {
  border: none !important;
}

.sun-editor .se-btn {
  color: rgba(0, 0, 0, 0.87) !important;
  border-radius: var(--prima-border-radius-small) !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}

.sun-editor-editable a.on {
  color: var(--v-anchor-base);
  background-color: unset;
}
</style>