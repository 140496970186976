import PlanitModel from './planit-model'
import Section from './section'

export default class SectionTranslationSuggestion extends PlanitModel {
    static fields() {
      return {
        ...super.fields(),

        viid: this.attr(),

        section_id: this.attr(),
        section: this.belongsTo(Section, 'section_id'),

        suggested_text_translation: this.attr(),
        suggested_text: this.attr(),

        suggested_info_text_translations: this.attr(),
        suggested_info_text: this.attr(),

        suggested_tooltip_info_text_translations: this.attr(),
        suggested_tooltip_info_text: this.attr(),

        data: this.attr({}),

      }

    }
}

SectionTranslationSuggestion.entity = 'section-translation-suggestions'
