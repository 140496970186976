

import store from '@state/store'
import DeeplSetting from '@state/models/deepl-setting'


export const state = {
  free_contingent: null,
  active: null,
}

export const mutations = {
  SET_ACTIVE_STATE(state, value) {
    state.active = value
  },
  SET_CONTINGENT(state, value) {
    state.free_contingent = value
  }
}

export const getters = {
  deeplSetting (state) {
    return DeeplSetting
      .query()
      .first()
    },
    active (state) {
        return state.active
    },
    free_contingent (state) {
        return state.free_contingent
    }
}

export const actions = {
  async initDeeplSetting({ commit, getters }) {
    await DeeplSetting.getDeeplSetting()
    const setting = await getters.deeplSetting
    if (setting && setting.has_api_key) {
      commit('SET_ACTIVE_STATE', setting.active)
      commit('SET_CONTINGENT', setting.free_contingent)
    }
  }
}
