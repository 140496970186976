import axios from 'axios'

import {
    DocumentBlueprintAvailability,
    DocumentBlueprintType,
    DocumentType
} from '@enums'

import store from '@state/store'

import { Document } from './document'
import { DocumentBlueprintVersion } from './document-blueprint-version'
import File from './file'
import FolderLink from './folder-link'
import PermissionRoleModelAssociation from './permission-role-model-association'
import PlanitModel from './planit-model'
import Section from './section'
import User from './user'

import downloadBlobResponse from '@utils/download-blob-response'


export default class DocumentBlueprint extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      name_translations: this.attr(),
      document_blueprint_type: this.attr(),
      availability: this.attr(),
      result_view_mode: this.attr(),

      document_blueprint_versions_length: this.attr(),

      document_blueprint_versions: this.hasMany(DocumentBlueprintVersion, 'document_blueprint_id'),

      current_document_blueprint_version_id: this.attr(),
      current_blueprint_version: this.belongsTo(
        DocumentBlueprintVersion,
        'current_document_blueprint_version_id'
      ),

      edit_document_blueprint_version_id: this.attr(),
      edit_document_blueprint_version: this.belongsTo(
        DocumentBlueprintVersion,
        'edit_document_blueprint_version_id'
      ),
      // edit_document_blueprint_version_id: this.attr(),

      documents: this.hasMany(Document, 'document_blueprint_id'),

      files: this.hasMany(File, 'document_blueprint_id'),

      parent_document_blueprint_id: this.attr(),
      parent_document_blueprint: this.belongsTo(DocumentBlueprint, 'parent_document_blueprint_id'),

      document_blueprint_variants: this.hasMany(DocumentBlueprint, 'parent_document_blueprint_id'),

      folder_links: this.hasMany(FolderLink, 'document_blueprint_id'),

      icon: this.attr(),
      planit_approved: this.attr(),

      permission_role_model_associations: this.hasMany(
        PermissionRoleModelAssociation,
        'document_blueprint_id'
      ),

      comment_text: this.attr(),
      content_status: this.attr(),
      formatting_status: this.attr(),
      translation_status: this.attr(),
      uuid: this.attr(),

      attribute_ids: this.attr(),
      attributes: this.hasManyBy(Section, 'attribute_ids'),

      company_id: this.attr(),

      repo_create_optional_button_text_translations: this.attr(),
      name_in_prima_folder_translations: this.attr(),

      last_edited_by_user_id: this.attr(),
      last_edited_by_user: this.belongsTo(User, 'last_edited_by_user_id'),

      is_available_for_creation_in_company: this.attr(),

      primary_locale: this.attr(),

      // locale fields
      current_locale: this.attr(),

      show_all_pages: this.attr(),

      primary_blueprint: this.attr(),

      minimum_license_type: this.attr(),
    }
  }

  static beforeCreate(model) {
    model.current_locale = store.getters['auth/currentLocale']
  }

  static getPrimaryBlueprints(params) {
    params = params || {}
    return axios.get('/api/primary-document-blueprints', { params })
  }

  static importPrimaryBlueprint(blueprint) {
    const params = { import: true }
    return axios.post('/api/primary-document-blueprints/' + blueprint.uuid, { ...params })
  }

  static async getByUUID(uuid, config) {
    return this.api().get(this.$url() + '/by-uuid/' + uuid, config)
  }

  getPrimaryTemplates(params) {
    params = params || {}
    return axios.get('/api' + this.url() + '/primary-instance-templates', { params })
  }

  importPrimaryTemplate(templateDocumentId) {
    const data = { document_id: templateDocumentId }
    return Document.api().post(this.url() + '/primary-instance-templates', data)
  }

  refreshBlueprintVersions(params) {
    return DocumentBlueprintVersion.api()
      .get(this.url() + DocumentBlueprintVersion.$url(), { params, save: false })
      .then((result) => {
        result.response.data.forEach((dbv) => {
          dbv.document_blueprint_id = this.id
        })

        return result.save().then(() => {
          return result
        })
      })
  }

  refreshBlueprintTemplates(companyId) {
    return Document.api()
      .get(this.url() + Document.$url(), {
        save: false,
        params: {
          templates: true,
          company_id: companyId || undefined,
        },
      })
      .then(async (result) => {
        result.response.data.forEach((d) => {
          d.document_blueprint_id = this.id
        })

        await result.save()
        return result
      })
  }

  createBlueprintTemplate(data) {
    return Document.api()
      .post(
        this.url() + Document.$url(),
        { ...data, document_type: DocumentType.TEMPLATE },
        { save: false }
      )
      .then(async (result) => {
        result.response.data.document_blueprint_id = this.id

        await result.save()
        return result
      })
  }

  refreshBlueprintVariants() {
    return DocumentBlueprint.api().get(this.url() + DocumentBlueprint.$url())
  }

  createBlueprintVariant(data) {
    return DocumentBlueprint.api()
      .post(this.url() + DocumentBlueprint.$url(), { ...data }, { save: false })
      .then(async (result) => {
        await result.save()
        return result
      })
  }

  resolveFiles(config) {
    return File.api()
      .get(this.url() + File.$url(), { ...config, save: false })
      .then((result) => {
        result.response.data.forEach((f) => {
          f.document_blueprint_id = this.id
          if (config && config.params && config.params.company_id) {
            f.company_id = config.params.company_id
          }
        })

        result.save()
        return result
      })
  }

  getCurrentBlueprintVersion(config) {
    return DocumentBlueprintVersion.api()
      .get(this.url() + '/current-blueprint-version', config)
      .then((result) => {
        return DocumentBlueprint.update({
          where: this.id,
          data: {
            current_blueprint_version: result.response.data,
            current_document_blueprint_version_id: result.response.data.id,
          },
        })
      })
  }

  refreshDocuments(config) {
    return Document.api().get(this.url() + Document.$url(), config)
  }

  upgradeDocuments(params) {
    return Document.api().post(this.url() + '/upgrade-documents', {}, { params })
  }

  cacheAllResultValues() {
    return DocumentBlueprint.api().post(this.url() + '/cache-all-result-values')
  }

  async commitVersion({ minorChange, discard, resetToVersion } = {}) {
    minorChange = minorChange || false
    discard = discard || false
    resetToVersion = resetToVersion || null

    const params = {
      commit: true,
      minor_change: minorChange,
      discard,
    }

    if (resetToVersion) {
      params.reset_to_version = resetToVersion
    }

    const result = await this.postOrPatch(params)
    return result
  }

  createFolderLink(data, config = {}) {
    return FolderLink.api().post(this.url() + FolderLink.$url(), { ...data }, { save: true, ...config })
  }

  getUpdateFromPrimaryInstance() {
    return axios.get('/api' + this.url() + '/primary-instance-blueprint')
  }

  async importPrimaryBlueprint(params = {}) {
    await this.updateAttr({ availability: DocumentBlueprintAvailability.UPGRADING })
    return axios.post('/api' + this.url() + '/primary-instance-blueprint', { upgrade: true, ...params })
  }

  getIcon() {
    return this.icon || (
      this.document_blueprint_type === DocumentBlueprintType.BLUEPRINT
        ? 'fal fa-book'
        : 'fal fa-album-collection'
    )
  }

  getRouterTo(companyId) {
    if (this.document_blueprint_type === DocumentBlueprintType.BLUEPRINT) {
      return {
        name: 'admin.documentBlueprint.sections.page',
        params: { documentBlueprintId: this.id, page: 1 },
      }

    } else {
      return {
        name: 'documents.repositories.detail',
        params: { repositoryId: this.id, companyId },
      }

    }
  }

  async getSectionDiffByVersionAndPosition({
    blueprintVersion,
    actualPosition,
  }) {
    return axios.get(
      '/api' + this.url() + '/section-diff-by-version-and-position',
      { params: { blueprintVersion, actualPosition } },
    )
  }

  async exportXlsx({ schemaOnly = false, company_id = null } = {}) {
    const params = {
      schema_only: schemaOnly,
    }

    if (!schemaOnly && company_id) {
      params.company_id = company_id
    }

    const response = await axios.post(
      '/api' + this.url() + '/export', {}, {
        responseType: 'blob',
        params,
      }
    )

    if (response?.status == 200) {
      downloadBlobResponse(response)
    }

    return response
  }

  async cancelAllUpgrading() {
    return axios.post('/api' + this.url() + '/cancel-all-upgrading')
  }

}

DocumentBlueprint.entity = 'document-blueprints'
