import axios from 'axios'

import { CompanyDataImportType, InviteTargetType, PermissionRoleType } from '@enums'

import store from '@state/store'

import Address from './address'
import CompanyRole from './company-role'
import { Document } from './document'
import DocumentBlueprint from './document-blueprint'
import File from './file'
import Folder from './folder'
import PlanitModel from './planit-model'
import ScormFileCompanyAssociation from './scorm-file-company-association'
import User from './user'

import DocsAuditProject from '@state/models/audit/docs-audit-project'
import Invite from '@state/models/invite'

import Approval from '@state/models/approval'
import CompanyLink from '@state/models/company-link'
import CompanyRoleScormStatus from '@state/models/company-role-scorm-status'
import License from '@state/models/license'
import MailConfig from '@state/models/mail-config'
import PermissionRole from '@state/models/permission-role'
import PermissionRoleCompanyAssociation from '@state/models/permission-role-company-association'
import CompanyPerson from './company-person'

import Event from '@state/models/event'
import TranslationContingent from '@state/models/translation-contingent'

import SsoConfiguration from '@state/models/sso-configuration'
import { emitter } from '@utils/global-events'
import setThemeColors from '@utils/theme'
export default class Company extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      company_roles: this.hasMany(CompanyRole, 'company_id'),
      company_persons: this.hasMany(CompanyPerson, 'company_id'),
      documents: this.hasMany(Document, 'company_id'),

      // address_id: this.attr(),
      address: this.hasOne(Address, 'company_id'),

      scorm_file_company_associations: this.hasMany(ScormFileCompanyAssociation, 'company_id'),

      name: this.attr(),

      invite_code: this.attr(),

      custom_color: this.attr(),
      custom_color_accent: this.attr(),
      custom_color_enabled: this.attr(),
      custom_color_accent_enabled: this.attr(),

      custom_email_html: this.attr(),
      enable_custom_email_for_company_group: this.attr(),

      image: this.attr(),

      website: this.attr(),
      is_eu_rep: this.attr(),
      is_data_processor: this.attr(),
      has_works_council: this.attr(),

      locale: this.attr(),
      content_locales: this.attr(),
      enable_translation_suggestions: this.attr(),

      position: this.attr(),

      is_company_group: this.attr(),
      demo_import_loading: this.attr(),

      company_role_count: this.attr(),
      // has_child_companies: this.attr(),

      me_can_create_documents: this.attr(),

      dpo_company_role_id: this.attr(),
      dpo_company_role: this.belongsTo(CompanyRole, 'dpo_company_role_id'),
      dpo_address_id: this.attr(),
      dpo_address: this.belongsTo(Address, 'dpo_address_id'),
      dpo_string: this.attr(),

      dpp_company_role_id: this.attr(),
      dpp_company_role: this.belongsTo(CompanyRole, 'dpp_company_role_id'),
      dpp_address_id: this.attr(),
      dpp_address: this.belongsTo(Address, 'dpp_address_id'),
      dpp_string: this.attr(),

      rep_company_role_id: this.attr(),
      rep_company_role: this.belongsTo(CompanyRole, 'rep_company_role_id'),
      rep_address_id: this.attr(),
      rep_address: this.belongsTo(Address, 'rep_address_id'),
      rep_string: this.attr(),

      parent_company_id: this.attr(),
      parent_company: this.belongsTo(Company, 'parent_company_id'),

      child_companies: this.hasMany(Company, 'parent_company_id'),

      events: this.hasMany(Event, 'company_id'),
      company_links: this.hasMany(CompanyLink, 'company_id'),
      mail_config: this.hasOne(MailConfig, 'company_id'),

      sso_configuration: this.hasOne(SsoConfiguration, 'company_id'),

      translation_contingents: this.hasMany(TranslationContingent, 'company_id'),

      translation_contingent_min_max: this.attr(),

      // helpers
      license_type: this.attr(), // is actually the active license's type
      scorm_participant_count: this.attr(),

      // local attributes
      selected: this.attr(), // determines the company context of all views
      allChildCompaniesSelected: this.attr(),
    }
  }

  async sync(attr, data, config, callback, { skipSetSelectedCompany = false } = {}) {
    return super.sync(attr, data, config, callback).then((r) => {
      if (store.getters['navbar/selectedCompany']?.id === r.response.data.id) {
        if (!skipSetSelectedCompany) {
          const updatedCompany = r.entities.companies.find((c) => c.id === r.response.data.id)
          store.dispatch('navbar/setSelectedCompany', {
            selectedCompany: updatedCompany,
            dontRedirect: true,
            skipSetNavTreeItems: true,
          })
        }
      }

      return r
    })
  }

  createDocumentFromBlueprint(
    documentBlueprint,
    template,
    name,
    parentFolderId,
    skipReloadEvent,
    deletePlaceholderFiles,
    newPermissionRoleId
  ) {
    const data = { document_blueprint_id: documentBlueprint.id }
    if (name) {
      data.name = name
    }

    if (template) {
      data.template_id = template.id
    }

    if (parentFolderId) {
      data.primary_folder_id = parentFolderId
    }

    if (deletePlaceholderFiles) {
      data.delete_placeholder_files = true
    }

    if (newPermissionRoleId) {
      data.permission_role_id = newPermissionRoleId
    }

    return Document.api()
      .post(this.url() + Document.$url(), data)
      .then((r) => {
        // emitter.emit('navbar:queryDocumentsByCompany', this)
        if (!skipReloadEvent) {
          emitter.emit('navbar:setNavTreeItems')
        }
        if (r.response.data) {
          const approvalData = { document_id: r.response.data.id }
          Approval.api().post('/approvals', approvalData)
        }
        return r
      })
  }
  createCompanyRole(user, additionalData) {
    additionalData = additionalData || {}
    const data = { user_id: user.id, ...additionalData }

    return this.createSubResource(CompanyRole, data)
  }

  createInvite(args, config = {}) {
    const data = {
      company_id: this.id,
      invite_target_type: InviteTargetType.COMPANY,
    }
    if (args.user) {
      data.user_id = args.user.id
    } else if (args.email) {
      data.email = args.email
    }

    if (args.invite_data) {
      data.invite_data = args.invite_data
    }

    return Invite.$new(data, config)
  }

  resolveDocuments(config) {
    return this.resolveSubResource(Document, config)
  }

  refreshDocuments(config) {
    return this.refreshSubResource(Document, config)
  }

  refreshAuditProjects() {
    return this.resolveSubResource(DocsAuditProject, { save: false }).then((result) => {
      if (result) {
        result.response.data.forEach((dap) => (dap.company = this))
        result.save()
      }
    })
  }

  refreshScormFileCompanyAssociations() {
    return this.resolveSubResource(ScormFileCompanyAssociation, {
      save: false,
    }).then((result) => {
      if (result) {
        result.response.data.forEach((sfca) => (sfca.company_id = this.id))
        return result.save()
      }
    })
  }

  uploadDocument(formData, onUploadProgress) {
    return File.api().request({
      method: 'post',
      url: this.url() + File.$url(),
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    })
  }

  getMyCompanyRole() {
    const currentUser = store.getters['auth/currentUser']
    const companyRole = CompanyRole.query()
      .where((cr) => cr.user_id === currentUser.id && cr.company_id === this.id)
      .first()

    return companyRole
  }

  refreshInvites() {
    return Invite.api()
      .get(this.url() + Invite.$url(), { save: false })
      .then((result) => {
        result.response.data.forEach((i) => {
          i.company_id = this.id
        })
        result.save()
        return result
      })
  }

  setThemeColor() {
    const brandSetting = store.getters['navbar/brandSetting']

    if (this.custom_color_enabled && this.custom_color) {
      setThemeColors({ type: 'primary', value: this.custom_color })
      setThemeColors({ type: 'background', value: this.custom_color })
    } else if (!brandSetting) {
      setThemeColors({ type: 'primary' })
      setThemeColors({ type: 'background' })
    }

    if (this.custom_color_accent_enabled && this.custom_color_accent) {
      setThemeColors({
        type: 'appbar',
        value: this.custom_color_accent,
      })
    } else if (!brandSetting) {
      setThemeColors({
        type: 'appbar',
      })
    }
  }

  searchUsers(searchString, params) {
    return User.$all({ search: searchString, company_id: this.id, ...params })
  }

  searchFiles(searchString, params) {
    return File.api().get(this.url() + File.$url(), {
      params: { search: searchString, ...params },
    })
  }

  exportAll({
    documentIds = null,
    folderIds = null,
    oneFile = false,
    primaExport = false,
    xlsxExport = false,
  } = {}) {
    const data = {}
    const config = {}

    if (documentIds && documentIds.length) {
      data.document_ids = documentIds
    }

    if (folderIds && folderIds.length) {
      data.folder_ids = folderIds
    }

    if (oneFile) {
      data.one_file = oneFile
    }

    if (primaExport) {
      data.prima_export = primaExport
      config.responseType = 'blob'
    } else if (xlsxExport) {
      data.xlsx_export = xlsxExport
      config.responseType = 'blob'
    }

    return axios.post('/api' + this.url() + '/bulk-export', data, config)
  }

  createFolder(
    name,
    parentFolder,
    skipReloadEvent,
    existingVendor,
    createSubfolderTemplates,
    config,
    isPrimaFolder = false,
    withGuide = false
  ) {
    const data = { name, with_guide: withGuide }
    if (parentFolder) {
      data.parent_folder_id = parentFolder.id
    }

    /* if (existingVendor) {
      data.vendor_id = existingVendor.id
    } */

    data.is_prima_folder = isPrimaFolder
    data.create_subfolder_templates = !!createSubfolderTemplates
    return Folder.api()
      .post(this.url() + Folder.$url(), data, config)
      .then((r) => {
        if (!skipReloadEvent) {
          emitter.emit('navbar:setNavTreeItems')
        }

        return r
      })
  }

  getParentCompany() {
    return Company.find(this.parent_company_id)
  }

  getDataByImportType(companyDataImportType, locale) {
    let name, address

    if (companyDataImportType === CompanyDataImportType.COMPANY_NAME) {
      name = this.name
    } else if (companyDataImportType === CompanyDataImportType.COMPANY_DATA) {
      name = this.name
      address = this.address
    } else if (companyDataImportType === CompanyDataImportType.REPRESENTATIVE_DATA) {
      name =
        this.rep_string ||
        (this.rep_company_role &&
          this.rep_company_role.user &&
          (this.rep_company_role.user.name || this.rep_company_role.user.email))
      address = this.rep_address
    } else if (companyDataImportType === CompanyDataImportType.DPO_DATA) {
      name =
        this.dpo_string ||
        (this.dpo_company_role &&
          this.dpo_company_role.user &&
          (this.dpo_company_role.user.name || this.dpo_company_role.user.email))
      address = this.dpo_address
    } else if (companyDataImportType === CompanyDataImportType.DPP_DATA) {
      name =
        this.dpp_string ||
        (this.dpp_company_role &&
          this.dpp_company_role.user &&
          (this.dpp_company_role.user.name || this.dpp_company_role.user.email))
      address = this.dpp_address
    }

    let retString = ''
    if (name) {
      retString += name
    }

    if (address) {
      retString += '<br>' + address.getMultilineText(locale)
    }

    return retString
  }

  refreshPendingDataImportDocuments(companyDataImportType, pendingDataCompanyPerson) {
    const params = {}
    if (pendingDataCompanyPerson) {
      params.company_person_id = pendingDataCompanyPerson.id
    } else if (companyDataImportType) {
      params.companyDataImportType = companyDataImportType
    }

    return Document.api().get(this.url() + '/pending-data-import-documents', {
      params,
    })
  }

  upgradePendingDataImportDocuments(documentIds, pendingDataCompanyPerson, companyDataImportType) {
    const data = { document_ids: documentIds }
    if (pendingDataCompanyPerson) {
      data.company_person_id = pendingDataCompanyPerson.id
    } else if (companyDataImportType) {
      // e.g. when company_person was deleted
      data.company_data_import_type = companyDataImportType
    }
    return Document.api().post(this.url() + '/pending-data-import-documents', data)
  }

  upgradeDocuments(documentIds, folderIds, config = {}) {
    const data = {}

    if (documentIds && documentIds.length) {
      data.document_ids = documentIds
    }

    if (folderIds && folderIds.length) {
      data.folder_ids = folderIds
    }

    return axios.post('/api' + this.url() + '/bulk-upgrade', data, config)
  }

  refreshDocumentStats() {
    return axios.get('/api' + this.url() + '/document-stats')
  }

  refreshCompanyStats() {
    return axios.get('/api' + this.url() + '/company-stats')
  }

  lastModifiedBlueprints() {
    return DocumentBlueprint.api().get(this.url() + '/last-changed-blueprints')
  }

  lastModifiedDocument(currentUserId) {
    return Document.api().get(
      this.url() + '/latest-modified-document?current_user_id=' + currentUserId
    )
  }

  refreshLatestDocuments(folderId, params) {
    params = params || {}
    if (!!folderId) {
      return Document.api().get(this.url() + '/latest-documents?folder_id=' + folderId, { params })
    }
    return Document.api().get(this.url() + '/latest-documents', { params })
  }

  refreshLatestChanges(folderId, params) {
    params = params || {}
    if (folderId) {
      return Document.api().get(this.url() + '/latest-document-changes?folder_id=' + folderId, {
        params,
      })
    }
    return Document.api().get(this.url() + '/latest-document-changes', { params })
  }

  createVendor(data) {
    return Vendor.api().post(this.url() + Vendor.$url(), data)
  }

  createLightAccount(data, config) {
    return CompanyRole.api().post(this.url() + '/direct-user', data, config)
  }

  createDirectAccount(data, config) {
    return Company.api().post(this.url() + '/direct-user', data, config)
  }

  async getNextLightAccountEmail() {
    const response = await axios.get('/api' + this.url() + '/light-accounts-next-email')

    return response.data.message
  }

  async refreshLicensePermissionRole({ includeBlueprintPermissionRole = false } = {}) {
    const params = {
      include_menu_folder_permission_role: true,
    }

    if (includeBlueprintPermissionRole) {
      params.include_blueprint_permission_role = true
    }

    return PermissionRole.api().get(this.url() + '/license-permission-role', { params })
  }

  getDocumentStatsFromParentFolder(folder_id) {
    return axios.get('/api' + this.url() + '/document-stats?folder_id=' + folder_id)
  }

  getLicensePermissionRole() {
    const prcas = PermissionRoleCompanyAssociation.allFast()
      .filter((prca) => prca.company_id === this.id)
      .map((prca) => ({
        ...prca,
        permission_role: PermissionRole.find(prca.permission_role_id),
      }))

    return (
      prcas.length &&
      prcas.find(
        (prca) => prca.permission_role?.permission_role_type === PermissionRoleType.LICENSE
      )?.permission_role
    )
  }

  getLicenseType() {
    if (this.license_type) {
      return this.license_type
    }

    const licensePermissionRole = this.getLicensePermissionRole()
    if (licensePermissionRole) {
      const activeLicense = License.allFast().find(
        (license) => license.permission_role_id === licensePermissionRole.id && license.is_active
      )

      return activeLicense?.license_type
    }
  }

  inviteAllUsers(inviteData) {
    return Company.api().post(this.url() + '/invite-all-users ', { inviteData: inviteData })
  }
  getTags() {
    return axios.get('/api' + this.url() + '/tags')
  }
  getFolderPermissionGroups(folder_id) {
    return axios.get('/api' + this.url() + '/folder-permission-groups', { params: { folder_id } })
  }
  async getCompanyRoleScormStatus(loadFinishedCourses = false, loadAll = false) {
    const promise = new Promise((resolve) => {
      const trainingContentPromises = []
      trainingContentPromises.push(
        this.refreshSubResource(CompanyRoleScormStatus, {
          params: {
            loadFinishedCourses: loadFinishedCourses,
            loadAll: loadAll,
          },
        })
      )

      Promise.allSettled(trainingContentPromises).then(resolve)
    })
    const results = await promise
    await this.updateAttr({ contentLoaded: true })
    return results
  }

  async refreshTrainingStats(training_session_id, start_date, end_date) {
    const id = typeof training_session_id === 'string' ? null : training_session_id

    const params = {
      training_session_id: id,
      loadAll: training_session_id === 'all',
      start_date: start_date,
      end_date: end_date,
    }

    return await axios.get('/api' + this.url() + '/training-stats', { params })
  }

  async refreshScormFileStats(training_session_id, start_date, end_date) {
    const id = typeof training_session_id === 'string' ? null : training_session_id

    const params = {
      training_session_id: id,
      loadAll: training_session_id === 'all',
      start_date: start_date,
      end_date: end_date,
    }

    return await axios.get('/api' + this.url() + '/scorm-file-stats', { params })
  }

  static async getDeletionConcept(companyId) {
    return await Document.api().get('/companies/' + companyId + '/deletion-concept')
  }

  static async createDeletionConcept(companyId) {
    return await Document.api().post('/companies/' + companyId + '/deletion-concept')
  }

  async downloadAllScormFileCertificates(crssIds) {
    return await File.api().post(this.url() + '/bulk-company-role-scorm-status-certificates', {
      crss_ids: crssIds,
    })
  }

  async refreshChildCompanies() {
    const result = await Company.api().get(this.url() + '/child-companies')
    if (result) {
      const resultIds = result.response.data.map((c) => c.id)
      return result?.entities?.companies.filter((c) => resultIds.includes(c.id))
    }
  }
  async mergeFolder(folderId, mergeFolderId) {
    return await Folder.api().patch(this.url() + '/folder-merge', {
      merge_folder_id: mergeFolderId,
      folder_id: folderId,
    })
  }
  async getSsoConfiguration() {
    return await SsoConfiguration.api()
      .get(this.url() + '/sso-configuration')
      .catch((error) => {
        if (error.response?.status === 404) {
          console.warn('SSO-Konfiguration nicht gefunden.')
          return null // Rückgabe von `null`
        }
      })
  }
  async createSsoConfiguration(data) {
    return await SsoConfiguration.api().post(this.url() + '/sso-configuration', data)
  }
  async loadActiveTranslationContingent(params) {
    return await TranslationContingent.api().get(this.url() + '/translation-contingent-is-active', {
      params,
    })
  }
}

Company.entity = 'companies'
