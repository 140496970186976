<script>
export default {
  name: 'PlanitAlert',
  components: {},
  props: {
    color: { type: String, required: false, default: 'primary' },
    buttonText: { type: String, required: false, default: null },
    buttonTo: { type: Object, required: false, default: null },
    buttonIcon: { type: String, required: false, default: null },
    buttonClass: { type: String, required: false, default: null },
    icon: {
      type: String,
      required: false,
      default: 'fal fa-exclamation-square',
    },
    dismissible: { type: Boolean, required: false, default: false },
    wrap: { type: Boolean, required: false, default: false },
    border: { type: String, required: false, default: null },
    noMaxWidth: { type: Boolean, required: false, default: false },
    alertClass: { type: String, required: false, default: null },
    alignCenter: { type: Boolean, required: false, default: false },
    outlined: { type: Boolean, required: false, default: false },
  },
  emits: ['update:model-value'],
  computed: {
    alertClasses() {
      const classes = []

      if (this.wrap) {
        classes.push('flex-wrap')
      }

      classes.push(this.alignCenter ? 'align-center' : 'align-baseline')

      return classes
    },
  },
  created() {},
  methods: {
    buttonClick() {
      this.$emit('click')
    },
  },
}
</script>

<template>
  <v-alert
    :color="color"
    :closable="dismissible"
    :border="border"
    class="planit-alert"
    :icon="icon"
    :class="alertClass"
    :style="noMaxWidth ? {} : { 'max-width' : '70rem' }"
    :variant="outlined ? 'outlined' : 'tonal'"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <div
      class="d-flex"
      :class="alertClasses"
    >

      <slot></slot>

      <PlanitButton
        v-if="buttonText"
        :color="color"
        :to="buttonTo"
        :class="buttonClass ? buttonClass : 'ml-auto'"
        variant="flat"
      >
        <PlanitIcon
          v-if="buttonIcon"
          size="small"
          class="mr-1"
          :icon="buttonIcon"
        />
        {{ buttonText }}
      </PlanitButton>
    </div>
  </v-alert>
</template>
