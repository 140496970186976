import axios from 'axios'

import {
  cloneDeep,
  sortBy,
} from 'lodash'

import {
  QuestionType,
} from '@enums'

import store from '@state/store'

import Address from './address'
import { Document } from './document'
import { DocumentVersion } from './document-version'
import File from './file'
import Folder from './folder'
import PlanitModel from './planit-model'
import RepositoryReferenceItem from './repository-reference-item'
import Section from './section'
import SectionReferenceItem from './section-reference-item'

import { emitter } from '@utils/global-events'


export default class Answer extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      section_id: this.attr(),
      section: this.belongsTo(Section, 'section_id'),

      viid: this.attr(),

      section_viid: this.attr(),

      current_section_id: this.attr(),
      current_section: this.hasOne(Section, 'answer_id', 'current_section_id'),

      parent_answer_viid: this.attr(),

      head_answer_id: this.attr(),
      head_answer: this.belongsTo(Answer, 'head_answer_id'),

      file_id: this.attr(),
      file: this.belongsTo(File, 'file_id'),

      address_id: this.attr(),
      address: this.belongsTo(Address, 'address_id'),

      commit_id: this.attr(),

      text: this.attr(),
      text_translations: this.attr(),
      data: this.attr({}),

      comment_text_translations: this.attr(),

      is_empty_cached_by_locale: this.attr(),

      result_text_translations: this.attr(),
      result_link: this.attr(),

      last_answer_id: this.attr(),

      select_group_option_viid: this.attr(),

      heading_position: this.attr(),
      result_list_item_level: this.attr(),
      result_list_item_position_string: this.attr(),
      result_visible: this.attr(),
      translation_suggestions: this.attr(),

      // local attributes
      updateText: this.attr(false),

      repository_reference_item_id: this.attr(),
      repository_reference_item: this.hasOne(RepositoryReferenceItem, '$id', 'repository_reference_item_id'),

      fix_repository_document_version: this.attr(),

    }
  }

  async handleSyncResponse(response, oldParentId) {
    // TODO: is this actually used?
    this.$delete()

  }

  sync(attr, data, config, callback) {
    const oldParentId = this.parent_answer_id
    data = data || this.getSyncData(attr)

    if (callback) {
      return super
        .postOrPatch(data, config)
        .then((r) => this.handleSyncResponse(r, oldParentId))
        .then((r) => {
          return this.syncQueueReplace(r)
        })
        .then(callback)
    } else {
      return super
        .postOrPatch(data, config)
        .then((r) => this.handleSyncResponse(r, oldParentId))
        .then((r) => {
          return this.syncQueueReplace(r)
        })
    }
  }

  getSortedItems() {
    if (!this.data) {
      return []
    }

    return sortBy(this.data.items, (i) => i.value)
  }

  uploadFile(formData, requestConfig) {
    requestConfig = requestConfig || {}

    return File.api().request({
      method: 'post',
      url: this.url() + File.$url(),
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      ...requestConfig,
    })
  }

  importUrl(url) {
    return File.api().post(this.url() + '/url-import', { url })
  }

  isEmptyIncludingSubanswers(section, documentVersion, locale) {
    return (
      this.isEmpty(locale) ||
      !!Section.allFast()
        .filter((_section) => _section.parent_section_viid === section.viid)
        .find((_section) => {
          _section = Section.find(_section.id)
          // TODO: do this for all cloned answers?
          return _section
            .getAnswerByDocumentVersion(documentVersion)
            .isEmptyIncludingSubanswers(_section, documentVersion, locale)
        })
    )
  }

  clone(documentVersion) {
    return axios.post('/api' + this.url() + '/clone').then(async (response) => {
      const result = await Answer.insert({
        data: response.data,
      })

      result.answers.forEach((answer) => {
        store.commit('currentDocument/UPDATE_ANSWER', { answer, documentVersion })
      })

      return result
    })
  }

  delete(documentVersion) {
    store.commit('currentDocument/DELETE_ANSWER', { answer: this, documentVersion })

    return super.delete()
  }

  async getGroupedSectionReferenceItems({ section, documentVersion, locale } = {}) {
    const response = await axios.get(
      '/api' + this.url() + '/section-reference-items',
      {
        params: {
          document_version_id: documentVersion.id,
          locale,
        }
      }
    )

    if (!response.data || !response.data.length) {
      return []
    }

    for (const item of response.data) {
      if (item.document) {
        item.document.current_locale = locale
      }
    }

    const result = await SectionReferenceItem.insert({
      data: response.data,
    })

    const promises = []
    const rriUpdateData = []
    const sriUpdateData = []

    const sriToPKMap = {}

    for (const sri of result['section-reference-items']) {
      if (!sri.referred_document_outdated) {
        if (sri.section_id && sri.answer_id) {
          const sriSection = Section.find(sri.section_id)
          if (sriSection.question_type === QuestionType.REPOSITORY_REFERENCE) {
            const sriAnswer = Answer.find(sri.answer_id)
            const sriDocumentVersion = DocumentVersion.find(sri.document_version_id)
            promises.push(
              sriAnswer.getRepositoryReferenceItems({ documentVersion: sriDocumentVersion, save: false })
                .then((responseData) => {
                  rriUpdateData.push(responseData)
                  sriToPKMap[sri.id] = [sriAnswer.viid, responseData.document_id]

                })
            )
          }
        }
      }
    }

    await Promise.allSettled(promises)

    const rriResult = await this.bulkInsertRepositoryReferenceItems(rriUpdateData)

    Object.entries(sriToPKMap).forEach(([sriId, pk]) => {
      const newRri = rriResult['repository-reference-items'].find(rri => rri.answer_viid === pk[0] && rri.document_id === pk[1])
      sriUpdateData.push({
        id: sriId,
        repository_reference_item_id: newRri.$id,
      })

    })

    await SectionReferenceItem.update({
      data: sriUpdateData,
    })

    const nextSectionReferenceItemUids = (result['section-reference-items'] || []).filter(
      sri => !!sri.next_section_reference_item_id
    ).map(
      sri => sri.next_section_reference_item_id
    )

    const queriedSectionReferenceItems = (result['section-reference-items'] || [])
    .filter(sri => nextSectionReferenceItemUids.indexOf(sri.id) === -1)
    .map((sri) => {
      // TODO: create explicit with
      return SectionReferenceItem.query().whereId(sri.id).withAllRecursive().first()
    })

    // if document === null -> deleted
    const groupedByDocumentItems = (result.documents || []).concat([null])
    .map((document) => {
      return {
        document: document && Document.query().whereId(document.id).with('primary_folder').first(),
        sectionGroups: sortBy(
          section.data.referred_sections.sections,
          s => s.position
        ).map((sectionViidOnly) => {
          const section = result.sections.find(s => s.viid === sectionViidOnly.viid)

          const sectionReferenceItems = queriedSectionReferenceItems.filter(
            sri => sri.section.viid === sectionViidOnly.viid && sri.document?.id === document?.id
          )

          return {
            section,
            sectionReferenceItems,
            colspan: section.text_translations.de.includes('Kategorien personenbezogener Daten') ? 4 : 1,
            isDeletedGroup: sectionReferenceItems.some(sri => sri.deleted),
          }

        })
      }
    })
    .filter(
      group => (
        !!group.document ||
        group.sectionGroups.some(
          sg => sg.sectionReferenceItems.length > 0
        )
      )
    ) // filter empty deleted group

    return groupedByDocumentItems

  }

  async getRepositoryReferenceItems({ documentVersion, save = true } = {}) {
    const response = await axios.get(
      '/api' + this.url() + '/repository-reference-items',
      {
        params: { document_version_id: documentVersion.id }
      }
    )

    if (!response.data) {
      return null
    }

    const result = await this.queryRepositoryReferenceItems({ data: response.data, documentVersion, save })

    return result

  }

  async bulkInsertRepositoryReferenceItems(data) {
    return RepositoryReferenceItem.insertOrUpdate({
      data,
      insert: ['repository-reference-items'],
    })
  }

  async queryRepositoryReferenceItems({ data, documentVersion, save = true } = {}) {
    // when saving while connected to section reference items, documentVersion doesnt have document_id or is_edit_document_version_of_id
    const firstExistingRri = RepositoryReferenceItem.allFast().find(rri => rri.repo_answer_viid === this.viid)
    const existingDocumentId = firstExistingRri?.document_id

    await RepositoryReferenceItem.delete(rri => rri.repo_answer_viid === this.viid)

    // save document_id to generate unique primary keys
    const document_id = (
      documentVersion.document_id ||
      documentVersion.is_edit_document_version_of_id ||
      existingDocumentId
    )
    const setDocumentIdRecursive = (rri) => {
      rri.document_id = document_id
      rri.repo_answer_viid = this.viid
      rri.children_repository_reference_items.forEach(setDocumentIdRecursive)
    }
    setDocumentIdRecursive(data)

    let result

    if (!save) {
      result = data

    } else {
      result = await RepositoryReferenceItem.insertOrUpdate({
        data: data,
        insert: ['repository-reference-items'],
      })

    }

    emitter.emit('repository-reference-items-updated:' + this.viid)

    return result

  }

  async patchViaDocumentVersion({ data, documentVersion }) {
    await this.updateAttr(data)

    await new Promise((resolve) => {
      const queueItem = {
        model: this,
        groupSaveModel: documentVersion,
        method: 'patch',
        data: {
          viid: this.viid,
          ...data,
        },
        params: {
          include_answer_repository_reference_items: true,
        },
        callback: resolve,
      }

      store.dispatch('syncQueue/queueNow', queueItem)

    })

  }

  getFileQuestionObjs() {
    const fileObjs = []
    if (this.file) {
      fileObjs.push({ file: this.file })
    }

    if (this.data?.files) {
      this.data.files.forEach((f) => {
        if (f.file_id) {
          const file = File.find(f.file_id)
          fileObjs.push({
            file,
            icon: file.getIcon() + ' fa-fw',
          })

        } else if (f.external) {
          fileObjs.push({
            external: f.external,
            icon: (f.icon || 'fal fa-link') + ' fa-fw',
          })

        } else if (f.document_id) {
          const _document = Document.find(f.document_id)
          fileObjs.push({
            document: _document,
            icon: 'fal fa-file-alt' + ' fa-fw',
            to: {
              name: 'documents.detail.page',
              params: {
                companyId: _document.company_id,
                documentId: f.document_id,
                page: 1,
              },
            },
          })

        } else if (f.folder_id) {
          const folder = Folder.find(f.folder_id)
          fileObjs.push({
            folder,
            icon: 'fal fa-folder' + ' fa-fw',
            to: {
              name: 'company.folders',
              params: {
                companyId: folder.company_id,
                folderId: f.folder_id,
              },
            },
          })

        }
      })
    }

    return fileObjs

  }

  async queryFileQuestionObjects() {
    const promises = []
    for (const file of (this.data?.files || [])) {
      if (file.document_id && !Document.allFast().find(d => d.id === file.document_id)) {
        promises.push(Document.$find(file.document_id))

      } else if (file.folder_id && !Folder.allFast().find(f => f.id === file.folder_id)) {
        promises.push(Folder.$find(file.folder_id))

      } else if (file.file_id && !File.allFast().find(f => f.id === file.file_id)) {
        promises.push(File.$find(file.file_id))

      }
    }

    await Promise.allSettled(promises)

  }

  getTranslationForLocale(locale) {
    if (!this.translation_suggestions) {
        return {}
    }
    return {
        suggested_text: this.translation_suggestions.suggested_text_translations?.[locale],
        suggested_comment_text: this.translation_suggestions.suggested_comment_text_translations?.[locale],
        suggested_items: this.translation_suggestions.suggested_item_translations?.[locale],
    }
  }

  async acceptTranslationSuggestions({ document = null, documentVersion = null, returnAnswerPatchData = false } = {}) {
      let newData = {}
      const currentLocale = document.current_locale

      for (const attr of ['text', 'comment_text', 'items']) {
          if (attr === 'items' && this.data?.items?.length) {
              const itemsSuggestions = this.translation_suggestions?.suggested_item_translations?.[currentLocale]
              if (!itemsSuggestions) {
                  continue
              }

              const oldData = cloneDeep(this.data)

              const newItems = this.data.items.map((item) => {
                  const itemWithSuggestion = itemsSuggestions.find(
                      (sItem) => sItem.value === item.value
                  )

                  if (itemWithSuggestion) {
                      const updatedItem = {
                          ...item,
                          custom_input_text: {
                              ...item.custom_input_text,
                              [currentLocale]: itemWithSuggestion.text,
                          },
                      }

                      return updatedItem
                  } else {
                      return item
                  }
              })

              newData = {
                  ...newData,
                  data: {
                      ...oldData,
                      items: newItems,
                  },
              }
          } else {
              const suggestion = this.getTranslationForLocale(currentLocale)[`suggested_${attr}`]
              if (!suggestion) {
                  continue
              }

              newData = {
                  ...newData,
                  [`${attr}_translations`]: {
                      ...this[attr + '_translations'],
                      [currentLocale]: suggestion,
                  },
                  data: {
                      ...cloneDeep(this.data),
                  },
              }
          }
      }

      if (Object.keys(newData).includes('data') && Object.keys(newData.data).length === 0) {
          delete newData.data
      }

      if (Object.keys(newData).length) {
          if (returnAnswerPatchData) {
              await this.updateAttr(newData)
              return newData
          } else {
              await this.patchViaDocumentVersion({
                  data: newData,
                  documentVersion,
              })
          }
      }
  }

  isEmpty(locale) {
    if (this.is_empty_cached_by_locale && this.is_empty_cached_by_locale[locale] !== undefined) {
      return this.is_empty_cached_by_locale[locale]
    } else {
      return true
    }
  }

}

Answer.entity = 'answers'
