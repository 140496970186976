import PlanitModel from './planit-model'

export default class DeeplSetting extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

        api_key: this.attr(),
        free_contingent: this.attr(),
        active: this.attr(),

        has_api_key: this.attr(),
    }
  }

  static async getDeeplSetting(params=null) {
    return await DeeplSetting.api().get('/deepl-settings',{ params }).catch((error) => {
      console.log("no deepl setting found")
    })
  }

  async patch(data) {
    return await DeeplSetting.api().patch(this.url(), data)
  }

  async patchServerConfig(data) {
    return await DeeplSetting.api().patch(this.url(), data)
  }

  static async createDeeplServerSetting(data) {
    return await DeeplSetting.api().post('/deepl-settings', data)
  }
  async delete() {
    return await DeeplSetting.api().delete(this.url())
  }

}

DeeplSetting.entity = 'deepl-settings'
