<script>
import PrimaInfoCenterArticleCard from '@components/info-center/prima-info-center-article-card.vue'
import PrimaInfoCenterChapterPanel from '@components/info-center/prima-info-center-chapter-panel.vue'
import PrimaInfoCenterTextHighlight from '@components/info-center/prima-info-center-text-highlight.vue'
import appConfig from '@src/app.config';

export default {
  name: 'PrimaInfoCenterSearch',
  components: {
    PrimaInfoCenterTextHighlight,
    PrimaInfoCenterChapterPanel,
    PrimaInfoCenterArticleCard,
  },
  props: {
    locale: {
      type: String,
      default: 'en',
    },
    searchTerm: {
      type: String,
      default: '',
    },
  },
  emits: ['article-click', 'chapter-article-click'],
  data: function () {
    return {
      loading: false,
      abortController: null,
      error: null,
      articles: [],
      chapters: [],
      showArticles: true,
      showChapters: true,
    }
  },
  methods: {
    async searchArticles(abortSignal) {
      const url = new URL(`${appConfig.strapiApiBaseUrl}/articles`)
      url.searchParams.set('locale', this.locale)
      url.searchParams.set('filters[$or][0][name][$containsi]', this.searchTerm)
      url.searchParams.set('filters[$or][1][paragraphs][text][$containsi]', this.searchTerm)
      url.searchParams.set('populate[paragraphs][filters][text][$containsi]', this.searchTerm)
      url.searchParams.set('populate[chapter][populate]', 'law_book')
      url.searchParams.set('populate[subchapter][populate][chapter][populate]', 'law_book')
      url.searchParams.set('sort', 'no:asc')
      url.searchParams.set('populate[paragraphs][sort]', 'no:asc')

      const response = await fetch(url, {
        method: 'GET',
        signal: abortSignal,
      })
      if (!response.ok) {
        throw new Error(`${response.url} - ${response.status}`)
      }

      return await response.json()
    },
    async searchChapters(abortSignal) {
      const url = new URL(`${appConfig.strapiApiBaseUrl}/chapters`)
      url.searchParams.set('locale', this.locale)
      url.searchParams.set('filters[name][$containsi]', this.searchTerm)
      url.searchParams.set('sort', 'no:asc')
      url.searchParams.set('populate[articles][sort]', 'no:asc')
      url.searchParams.set('populate[subchapters][populate][articles][sort]', 'no:asc')
      url.searchParams.set('populate', 'law_book')

      const response = await fetch(url, {
        method: 'GET',
        signal: abortSignal,
      })
      if (!response.ok) {
        throw new Error(`${response.url} - ${response.status}`)
      }

      return await response.json()
    },
    async search() {
      this.loading = true
      this.error = null

      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      try {
        const abortSignal = this.abortController.signal
        const articles = await this.searchArticles(abortSignal)
        const chapters = await this.searchChapters(abortSignal)

        this.articles = articles.data
        this.chapters = chapters.data
        this.loading = false
      } catch (e) {
        if (e.name === 'AbortError') {
          return
        }

        this.error = this.$t('http_error')
        this.articles = []
        this.chapters = []
        this.loading = false

        throw e
      }
    },
    handleSelectArticle(article) {
      const paragraphNo =
        article.attributes.paragraphs.data.length > 0
          ? article.attributes.paragraphs.data[0].attributes.no
          : null

      this.$emit('article-click', article, paragraphNo)
    },
    handleSelectChapterArticle(chapter, article) {
      const lawBook = chapter.attributes.law_book.data

      this.$emit('chapter-article-click', lawBook, article)
    },
    toggleShowArticles() {
      this.showArticles = !this.showArticles

      if (!this.showArticles && !this.showChapters) {
        this.toggleShowChapters()
      }
    },
    toggleShowChapters() {
      this.showChapters = !this.showChapters

      if (!this.showChapters && !this.showArticles) {
        this.toggleShowArticles()
      }
    },
  },
  watch: {
    searchTerm: async function () {
      await this.search()
    },
  },
  computed: {
    noResults() {
      return !this.loading && this.articles.length === 0 && this.chapters.length === 0
    },
  },
  async created() {
    await this.search()
  },
}
</script>

<template>
  <div>
    <v-alert
      v-if="error"
      type="error"
    >
      {{ error }}
    </v-alert>

    <v-progress-linear
      v-if="loading"
      color="primary"
      class="mb-2"
      indeterminate
    ></v-progress-linear>

    <div v-if="noResults">
      {{ $t('info_center.no_results') }}
    </div>
    <div
      v-else
      class="d-flex flex-column ga-3"
    >
      <div
        v-if="chapters.length && articles.length"
        class="d-flex flex-row ga-1"
      >
        <PlanitButton
          :color="showArticles ? 'primary' : 'white'"
          @click="toggleShowArticles"
          size="small"
          >{{ $t('info_center.article') }}</PlanitButton
        >
        <PlanitButton
          :color="showChapters ? 'primary' : 'white'"
          @click="toggleShowChapters"
          size="small"
          >{{ $t('info_center.chapter') }}</PlanitButton
        >
      </div>

      <div
        v-if="showArticles"
        class="d-flex flex-column ga-2"
      >
        <PrimaInfoCenterArticleCard
          v-for="article in articles"
          :key="article.id"
          :article="article"
          :highlight-query="searchTerm"
          @click="handleSelectArticle(article)"
        >
          <div v-if="article.attributes.paragraphs.data.length">
            <span
              >{{
                $t('info_center.paragraph_no', {
                  paragraphNo: article.attributes.paragraphs.data[0].attributes.no,
                })
              }}&nbsp;</span
            >
            <prima-info-center-text-highlight
              :text="article.attributes.paragraphs.data[0].attributes.text"
              :query="searchTerm"
              cut
              class="d-inline"
            />
          </div>
        </PrimaInfoCenterArticleCard>
      </div>

      <v-expansion-panels
        v-if="showChapters"
        class="ga-2"
        variant="accordion"
        flat
        tile
        multiple
      >
        <prima-info-center-chapter-panel
          v-for="chapter in chapters"
          :key="chapter.id"
          :chapter="chapter"
          :highlight-query="searchTerm"
          v-on:article-click="handleSelectChapterArticle(chapter, $event)"
        />
      </v-expansion-panels>
    </div>
  </div>
</template>
