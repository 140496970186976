import axios from 'axios'
import VueCookies from 'vue-cookies'

import * as Sentry from '@sentry/vue'


// import i18n from '@plugins/vue-i18n'

import { LicenseType, PermissionRoleType } from '@enums'

import License from '@state/models/license'
import PermissionRoleCompanyAssociation from '@state/models/permission-role-company-association'
import PermissionRoleUserAssociation from '@state/models/permission-role-user-association'
import User from '@state/models/user'
import store from '@state/store'


export const state = {
  accessToken: getSavedState('auth.accessToken'),
  currentUser: getSavedState('auth.currentUser'),

  impersonatorToken: getSavedState('auth.impersonatorToken'),
  impersonatorUser: getSavedState('auth.impersonatorUser'),

  tmpCookieLocale: null, // cookies are not reactive and this is used when it is changed without reload
  validatePromise: null,
  initialResolveDone: false,

  registerToken: null,

  // isAdmin: false,
  adminShowSelectedCompanyOnly: getSavedState('auth.adminShowSelectedCompanyOnly', false),

  debugVerificationLink: null,
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
  },
  SET_ACCESS_TOKEN(state, newValue) {
    state.accessToken = newValue
    saveState('auth.accessToken', newValue)
    setDefaultAuthHeaders(state)
  },
  SET_IMPERSONATOR_TOKEN(state, newValue) {
    state.impersonatorToken = newValue
    saveState('auth.impersonatorToken', newValue)
  },
  SET_IMPERSONATOR_USER(state, newValue) {
    state.impersonatorUser = newValue
    saveState('auth.impersonatorUser', newValue)
  },
  SET_TMP_COOKIE_LOCALE(state, newValue) {
    state.tmpCookieLocale = newValue
  },
  SET_VALIDATE_PROMISE(state, newValue) {
    state.validatePromise = newValue
  },
  SET_REGISTER_TOKEN(state, newValue) {
    state.registerToken = newValue
  },
  SET_AUTH_COOKIE(state) {
    setAuthCookie(state)
  },
  SET_INITIAL_RESOLVE_DONE(state, value) {
    state.initialResolveDone = value
  },
  SET_ADMIN_SHOW_SELECTED_COMPANY_ONLY(state, value) {
    // double check that user is actually admin
    if (state.currentUser?.is_admin) {
      saveState('auth.adminShowSelectedCompanyOnly', value)
      state.adminShowSelectedCompanyOnly = value
    }
  },
  SET_DEBUG_VERIFICATION_LINK(state, verification_link) {
    state.debugVerificationLink = verification_link
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.accessToken
  },
  currentUser(state) {
    return state.currentUser
  },
  user(state) {
    return state.currentUser && User.find(state.currentUser.id)
  },
  localeCookie(state) {
    return VueCookies.get('locale')
  },
  currentLocale(state) {
    if (state.currentUser && state.currentUser.locale) {
      return state.currentUser.locale

    } else if (state.tmpCookieLocale) {
      return state.tmpCookieLocale

    } else if (VueCookies.get('locale')) {
      return VueCookies.get('locale')

    } else if (window.lang) {
      return window.lang

    } else {
      return 'en'

    }
  },
  impersonator(state) {
    return state.impersonatorUser
  },
  isAdmin(state) {
    return state.currentUser?.is_admin_explicitly_enabled || false
  },
  adminShowSelectedCompanyOnly(state) {
    return state.adminShowSelectedCompanyOnly
  },
  activeLicensePermissionRole(state) {
    if (!state.currentUser) {
      return null
    }

    if (!store.getters['navbar/selectedCompany']) {
      return null
    }

    const prca = PermissionRoleCompanyAssociation.query()
      .with('permission_role')
      .with('permission_role.licenses')
      .where((prca) => prca.company_id === store.getters['navbar/selectedCompany'].id)
      .all()
      .find(
        (prca) =>
          prca.permission_role.permission_role_type === PermissionRoleType.LICENSE &&
          prca.permission_role.licenses.some((l) => l.is_active)
      )

    if (prca) {
      return prca.permission_role
    }
  },
  currentUserPruasFast(state, getters) {
    return PermissionRoleUserAssociation.allFast()
      .filter(prua => prua.user_id === state.currentUser.id)
  },
  currentUserPruasWithMappedPermissionRole(state, getters) {
    return getters.currentUserPruasFast
      .map((prua) => {
        return PermissionRoleUserAssociation
          .query()
          .whereId(prua.id)
          .with('permission_role.licenses')
          .first()
      })
  },
  activeResellerLicense(state, getters) {
    if (!state.currentUser) {
      return null
    }

    if (state.currentUser.is_admin && !!window.primary_instance) {
      return null
    }

    const prua = getters.currentUserPruasWithMappedPermissionRole
      .find(
        (prua) =>
          prua.permission_role.permission_role_type === PermissionRoleType.LICENSE &&
          prua.permission_role.licenses.some(
            (l) =>
              l.is_active &&
              (l.license_type === LicenseType.RESELLER ||
                l.license_type === LicenseType.PROFESSIONAL)
          )
      )

    if (prua) {
      return prua.permission_role.licenses.find(
        (l) =>
          l.is_active &&
          (l.license_type === LicenseType.RESELLER || l.license_type === LicenseType.PROFESSIONAL)
      )
    } else if (state.currentUser.is_admin && !window.primary_instance) {
      // get professional license for instance
      return License.query()
        .where((l) => l.is_active && l.license_type === LicenseType.PROFESSIONAL)
        .first()
    }
  },
  activeLicense(state, getters) {
    if (!getters.activeLicensePermissionRole) {
      return LicenseType.FREE
    }

    return getters.activeLicensePermissionRole.getActiveLicense()
  },
  activeLicenseType(state, getters) {
    if (!getters.activeLicensePermissionRole) {
      return LicenseType.FREE
    }

    if (!getters.activeLicense.is_active) {
      return LicenseType.FREE
    }

    return getters.activeLicense.license_type
  },
  hasProFeatures(state, getters) {
    return !!getters.activeLicenseType && [
      LicenseType.PRO,
      LicenseType.PROFESSIONAL,
      LicenseType.RESELLER,
    ].indexOf(getters.activeLicenseType) !== -1
  },
  validatePromise(state) {
    return state.validatePromise
  },
  initialResolveDone(state) {
    return state.initialResolveDone
  },
  wasHereBefore() {
    return localStorage.getItem('currentUser') !== null
  },
  activeUserLicensePermissionRoles(state, getters) {
    if (!state.currentUser) {
      return null
    }

    const pruas = getters.currentUserPruasWithMappedPermissionRole
      .filter(
        (prua) =>
          prua.permission_role.permission_role_type === PermissionRoleType.LICENSE &&
          prua.permission_role.licenses.some(
            (l) => l.is_active
          )
      )

    if (pruas) {
      return pruas.map(prua => prua.permission_role)
    }
  },
  debugVerificationLink(state) {
    return state.debugVerificationLink
  }
}

export const actions = {
  // This is automatically run from app.vue when initial route is available
  async init({ state, dispatch }) {
    setDefaultAuthHeaders(state)
    // setForbiddenIntercepter()
    await dispatch('validate')
  },

  // Logs in the current user.
  logIn(
    { commit, dispatch, getters },
    {
      email,
      password,
      $root,
      twoFactorCode,
      skipSaml,
      inviteCode,
      cb,
      logout,
      validate,
      checkSaml,
    } = {}
  ) {
    if (!logout && getters.loggedIn) return dispatch('validate')

    const loginData = { email, password }

    if (twoFactorCode) {
      loginData.two_factor_code = twoFactorCode
    }

    if (skipSaml) {
      loginData.skip_saml = true
    }

    if (checkSaml) {
      loginData.check_saml = true
    }

    if (inviteCode) {
      loginData.invite_code = inviteCode
    }

    const params = {}

    if (validate) {
      params.validate = true
    }

    return axios.post('/auth/login', loginData, { params }).then(async (response) => {
      if (!response.data.access_token) {
        return response

      } else {
        // setting the access token reloads the navbar + navtree
        commit('SET_CURRENT_USER', { id: response.data.user_id })
        commit('SET_ACCESS_TOKEN', response.data.access_token)
        commit('SET_AUTH_COOKIE')

        await dispatch('validate', { $root })
        // route isnt loaded and checkSelectedApp tries to init dpd navtree items
        await store.dispatch('navbar/initNavbar', { skipCheckSelectedApp: true })

        return response
      }
    })
  },

  async logInByCookieAndUserId({ commit, dispatch, getters }, { userId, $root } = {}) {
    let jwtToken = VueCookies.get('jwt_token')
    if (!jwtToken) {
      // check whether alternatively token was sent via get param because of different domain
      const params = new URLSearchParams(location.search)
      jwtToken = params.get('jwt_token')
      if (!jwtToken) {
        return Promise.reject(new Error())
      }
    }

    store.commit('navbar/SET_NAVBAR_LOADING', true)
    store.commit('navbar/SET_CONTENT_LOADING', true)

    commit('SET_ACCESS_TOKEN', jwtToken)
    commit('SET_CURRENT_USER', { id: userId })

    await dispatch('validate', { $root, refresh: true })
    await $root.$nextTick()
    await store.dispatch('navbar/initNavbar', { skipCheckSelectedApp: true })
    store.commit('navbar/SET_CONTENT_LOADING', true)

  },

  // Logs out the current user.
  async logOut({ commit }, { refreshUrl }) {
    store.commit('navbar/SET_CONTENT_LOADING', true)
    store.commit('navbar/SET_NAVBAR_LOADING', true)

    commit('SET_CURRENT_USER', null)
    commit('SET_ACCESS_TOKEN', null)
    commit('SET_AUTH_COOKIE')

    commit('SET_IMPERSONATOR_USER', null)
    commit('SET_IMPERSONATOR_TOKEN', null)

    commit('SET_ADMIN_SHOW_SELECTED_COMPANY_ONLY', false)

    Sentry.withScope((scope) => {
      scope.setUser(null);
    })

    window.localStorage.clear()

    // window.location.reload(true)
    window.location.href = refreshUrl || '/'
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  validate({ commit, state, getters, dispatch }, { $root, routeTo, refresh } = {}) {
    if (!state.currentUser) {
      commit('SET_CURRENT_USER', null)
      commit('SET_ACCESS_TOKEN', null)

      commit('SET_IMPERSONATOR_USER', null)
      commit('SET_IMPERSONATOR_TOKEN', null)

      return Promise.resolve(null)
    }

    if (state.validatePromise) {
      return state.validatePromise
    }

    const params = { validate: true }
    if (state.initialResolveDone && !refresh) {
      params.minimal = true

    } else {
      params.includeCompanyRoles = true
      params.includePermissionRoleUserAssociations = true
      params.includeOrders = true

    }

    const promise = User.$find(state.currentUser.id, { params })
      .then(async (result) => {
        commit('SET_VALIDATE_PROMISE', null)

        if (result?.response?.data?.show_downtime) {
          window.location.href = '/'
        }

        const user = User.find(result.response.data.id)
        commit('SET_CURRENT_USER', user)

        // is already called in navbar.js
        /* if (store.getters['navbar/selectedCompany']) {
          store.dispatch('checklist/refreshActiveChecklist')
        } */

        if (!state.initialResolveDone) {
          commit('SET_INITIAL_RESOLVE_DONE', true)

        }

        setAuthCookie(state)

        if (user.force_logout) {
          user.postOrPatch({ force_logout: false }).then(() => {
            dispatch('logOut', {})
          })
        }

        if (window.app_version !== undefined && user.app_version !== window.app_version) {
          await store.dispatch('syncQueue/handleQueueNow')
          if (routeTo) {
            window.location.href = window.location.origin + routeTo.fullPath
          } else {
            window.location.href = window.location.reload(true)
          }
        }

        const currentHub = Sentry.getCurrentHub();
        if (currentHub) {
          const scope = currentHub.getScope();
          if (scope) {
            scope.setUser({ email: user.email, id: user.id });
          }
        }

        if ($root) {
          setLocale(getters, $root)
        }

        // only set timezone when not impersonating
        if (!state.impersonatorToken) {
          const d = new Date()
          const offset = d.getTimezoneOffset() * -1

          if (!user.timezone || user.timezone !== offset) {
            return user.sync(null, { timezone: offset }).then((r) => {
              return r.entities.users.find((u) => u.id === r.response.data.id)
            })
          } else {
            return user
          }
        } else {
          return user
        }
      })
      .catch((error) => {
        commit('SET_VALIDATE_PROMISE', null)

        if (error.response) {
          if (error.response.status === 401) {
            dispatch('logOut', { refreshUrl: '/login' })
          } else if (error.response.status === 403) {
            // wrong user in localstorage
            dispatch('logOut', { refreshUrl: '/login' })
          } else if (error.response.status === 500) {
            dispatch('logOut', { refreshUrl: '/login' })
          }
        }

        return null
      })

    commit('SET_VALIDATE_PROMISE', promise)

    return promise
  },

  authRegister(
    { commit, dispatch, getters, state },
    {
      email,
      password,
      firstName,
      lastName,
      inviteCode,
      inviteCodeCode,
      sfcaInviteCode,
      companyInviteCode,
      tos_accepted,
      opt_in_newsletter,
      opt_in_tour,
    }
  ) {
    const data = { email, password, token: state.registerToken }

    if (inviteCode) {
      data.invite_code = inviteCode
    }

    if (inviteCodeCode) {
      data.invite_code_code = inviteCodeCode
    }

    if (sfcaInviteCode) {
      data.sfca_invite_code = sfcaInviteCode
    }

    if (companyInviteCode) {
      data.company_invite_code = companyInviteCode
    }

    if (firstName) {
      data.first_name = firstName
    }

    if (lastName) {
      data.last_name = lastName
    }

    if (tos_accepted) {
      data.tos_accepted = tos_accepted
    }

    if (opt_in_newsletter) {
      data.opt_in_newsletter = opt_in_newsletter
    }

    if (opt_in_tour) {
      data.opt_in_tour = opt_in_tour
    }

    if (store.getters['global/die3Mode']) {
      data.create_die3_basket = true
    }

    data.locale = getters.currentLocale

    if (window.gtag) {
      const sendTo = 'AW-933506141/' + (window.instance_mode === 'production' ?
        'UxYfCK_V_JIYEN3YkL0D' : '3hRtCLyQy5kYEN3YkL0D')

      console.log('sending conversion ping', 'event', 'conversion', {
        'send_to': sendTo,
        'value': 0.0,
        'currency': 'EUR',
        'transaction_id': ''
      })

      window.gtag('event', 'conversion', {
        'send_to': sendTo,
        'value': 0.0,
        'currency': 'EUR',
        'transaction_id': ''
      })

    }

    if (store.getters['global/partnerCode']) {
      data.partner_code = store.getters['global/partnerCode']
    }

    return axios.post('/api/users', data).then((response) => {
      if (response.status === 200) {
        if (response.data?.login === true) {
          return 'login'

        } else if (!response.data?.id) {
          return 'empty'

        }
      }

      if (response.data?.verification_link) {
        commit('SET_DEBUG_VERIFICATION_LINK', response.data.verification_link)
      }

      if (window.gtag) {
        window.gtag('event', 'register_success', {
          'event_category': 'register_success',
          'event_label': 'register_success',
          'value': true,
        })
      }

      return dispatch('logIn', { email, password, skipSaml: true })

    })
  },
  getRegisterToken({ commit, dispatch, getters }) {
    return axios.post('/auth/register-token').then((response) => {
      commit('SET_REGISTER_TOKEN', response.data.token)
    })
  },

  setLocale({ commit, state }, { locale, reload, $root, dontSetCookie }) {
    if (!state.currentUser) {
      if (!dontSetCookie) {
        VueCookies.set('locale', locale)
      }
      commit('SET_TMP_COOKIE_LOCALE', locale)
      if (reload !== undefined && reload === false && !!$root) {
        window.lang = locale
        $root.$i18n.locale = locale
      } else {
        location.reload(true)
      }
    } else {
      return axios.patch('/api/users/' + state.currentUser.id, { locale }).then((response) => {
        commit('SET_CURRENT_USER', response.data)
        if (reload !== undefined && reload === false && !!$root) {
          window.lang = locale
          $root.$i18n.locale = locale
        } else {
          location.reload(true)
        }
      })
    }
  },

  impersonate({ commit, dispatch, getters, state }, { token, userId }) {
    store.commit('navbar/SET_NAVBAR_LOADING', true)

    commit('SET_IMPERSONATOR_USER', state.currentUser)
    commit('SET_IMPERSONATOR_TOKEN', state.accessToken)

    commit('SET_ACCESS_TOKEN', token)
    commit('SET_AUTH_COOKIE')
    commit('SET_CURRENT_USER', { id: userId })
    commit('SET_ADMIN_SHOW_SELECTED_COMPANY_ONLY', false)

    return dispatch('validate')
  },
  stopImpersonating({ commit, dispatch, getters, state }) {
    store.commit('navbar/SET_NAVBAR_LOADING', true)

    commit('SET_ACCESS_TOKEN', state.impersonatorToken)
    commit('SET_AUTH_COOKIE')
    commit('SET_CURRENT_USER', state.impersonatorUser)

    commit('SET_IMPERSONATOR_USER', null)
    commit('SET_IMPERSONATOR_TOKEN', null)

    return dispatch('validate')
  },
  async syncIsAdminExplicitlyEnabled({ commit, getters }, value) {
    const result = await getters.user.sync(null, { is_admin_explicitly_enabled: value })
    commit('SET_CURRENT_USER', result.response.data)
    return result
  },
}

// ===
// Private helpers
// ===

function getSavedState(key, defaultValue) {
  defaultValue = defaultValue !== undefined ? defaultValue : null
  return JSON.parse(window.localStorage.getItem(key)) || defaultValue
}

function saveState(key, state) {
  if (state === null) {
    window.localStorage.removeItem(key)
  } else {
    window.localStorage.setItem(key, state ? JSON.stringify(state) : null)
  }
}

function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.accessToken ? 'JWT ' + state.accessToken : ''

  if (state.impersonatorToken) {
    axios.defaults.headers.common['X-PRIMA-IMPERSONATOR-JWT'] = 'JWT ' + state.impersonatorToken

  } else if (axios.defaults?.headers?.common['X-PRIMA-IMPERSONATOR-JWT'] !== undefined) {
    delete axios.defaults.headers.common['X-PRIMA-IMPERSONATOR-JWT']

  }
}

function setForbiddenIntercepter() {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 403) {
        store.dispatch('global/setShowUnauthorizedError', true)
      }

      return Promise.reject(error)
    }
  )

}

function setAuthCookie(state) {
  if (state.accessToken) {
    VueCookies.set('jwt_token', state.accessToken, '60d')
  } else {
    VueCookies.remove('jwt_token')
  }
}

function setLocale(getters, $root) {
  window.lang = getters.currentLocale
  $root.$i18n.locale = getters.currentLocale
}
