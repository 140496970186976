import { debounce } from 'lodash'

import { app } from '@src/main'

import store from '@state/store'
import { OrderType, LicenseType } from '@enums'
import Basket from '@state/models/basket'
import { dispatch } from 'd3'


export const state = {
  unpaidBasketId: null,
  unpaidBasket: null,
}

export const mutations = {
  SET_UNPAID_BASKET_ID(state, unpaidBasketId) {
    state.unpaidBasketId = unpaidBasketId
  },
  SET_UNPAID_BASKET(state, basket) {
    state.unpaidBasket = basket;
  },
}

export const getters = {
  user() {
    return store.getters['auth/user']
  },
  unpaidBasket(state) {
    return state.unpaidBasket
  },
}

export const actions = {
  init({ state, dispatch, getters }) {
  },
  async setUnpaidBasket({ commit, state }) {
    if (!state.unpaidBasketId) return

    const basket = await Basket
      .query()
      .whereId(state.unpaidBasketId)
      .with('orders')
      .with('billing_address')
      .first()

    commit('SET_UNPAID_BASKET', basket)
  },
  async refreshUnpaidBasket({ commit, dispatch, getters }) {
    const user = getters.user
    const result = await user.refreshSubResource(Basket, { url: user.url() + '/unpaid-basket' })
      .catch((error) => {
        if (error?.response?.status === 404) {
          commit('SET_UNPAID_BASKET_ID', null)
        }
      })

    if (result?.response?.data?.id) {
      commit('SET_UNPAID_BASKET_ID', result.response.data.id)
      dispatch('setUnpaidBasket')
    }

    return result
  },
}
